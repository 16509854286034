import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mantine/core';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import HeaderView from '../../common/HeaderView';
import TableView from '../../common/TableView';
import { ECOM_REPORT_ENUM, REPORT_CATEGORY_LIST } from './reportsConfig';
import { useReportHistory } from '../../../helpers/reportHelper';

const TABLE_COLUMNS = [
  {
    label: 'Title',
    value: 'title',
    sortable: true
  },
  {
    label: 'Type',
    value: 'type',
    sortable: true
  },
  {
    label: 'Last viewed',
    value: 'last_viewed',
    sortable: true
  }
];

const ReportTableListView = ({ reports, baseUrl }) => {
  const { reportHistory } = useReportHistory();
  const [filterState, setFilterState] = useState({
    search: '',
    fkReportCategory: null
  });

  return (
    <HeaderView
      actionButton={
        <Button
          color="dark"
          component={Link}
          size="sm"
          to={`${baseUrl}/reports/${ECOM_REPORT_ENUM.FINANCE_SUMMARY}`}
        >
          View summary
        </Button>
      }
      breadcrumbs={[
        {
          title: 'Reports',
          to: `${baseUrl}/reports`
        }
      ]}
    >
      <TableView
        columns={TABLE_COLUMNS}
        defaultSort="last_viewed"
        defaultSortDescending
        emptyMessage="No reports found."
        filters={[
          {
            key: 1,
            label: 'Search',
            placeholder: 'Filter by name',
            value: filterState.search,
            onChange: (value) =>
              setFilterState({ ...filterState, search: value })
          },
          {
            key: 2,
            label: 'Category',
            type: 'select',
            placeholder: 'Filter by type',
            value: filterState.fkReportCategory,
            searchable: true,
            clearable: true,
            data: REPORT_CATEGORY_LIST,
            onChange: (value) =>
              setFilterState({ ...filterState, fkReportCategory: value })
          }
        ]}
        minRowHeight="unset"
        rows={reports
          .filter(
            (r) =>
              !r.isHidden &&
              (!filterState.fkReportCategory ||
                r.fkReportCategory === filterState.fkReportCategory) &&
              (!filterState.search ||
                r.title
                  .toLowerCase()
                  .includes(filterState.search?.toLowerCase()) ||
                r.description
                  ?.toLowerCase()
                  .includes(filterState.search?.toLowerCase()))
          )
          .map((a) => {
            const viewHistory = reportHistory.reports.find(
              (f) => f.uuid === a.uuid
            );

            return {
              key: a.uuid,
              columns: [
                {
                  key: 1,
                  label: a.title,
                  component: Link,
                  to: `${baseUrl}/reports/${a.uuid}`,
                  weight: 500,
                  subLabel: a.description
                },
                {
                  key: 2,
                  label: REPORT_CATEGORY_LIST.find(
                    (f) => f.value.toString() === a.fkReportCategory.toString()
                  ).label
                },
                {
                  key: 3,
                  label: viewHistory
                    ? dayjs(viewHistory.date).format('MMM DD, YYYY')
                    : '',
                  sortValue: viewHistory ? new Date(viewHistory.date) : null,
                  sortValueType: 'date'
                }
              ]
            };
          })}
        tableTitle="Reports"
      />
    </HeaderView>
  );
};

ReportTableListView.propTypes = {
  baseUrl: PropTypes.string,
  reports: PropTypes.array
};

export default ReportTableListView;
