import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppStack from './AppStack';
import ResponsiveModal from './ResponsiveModal';
import AppText from './AppText';
import CustomSelect from './CustomSelect';

const DuplicateResourcesModal = ({
  isLoading,
  isOpen,
  onSubmit,
  onClose,
  message,
  title,
  resourceName,
  resourceOptions,
  children
}) => {
  const [formState, setFormState] = useState({ resourceId: '' });

  useEffect(() => {
    if (isOpen) {
      setFormState({ resourceId: '' });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      formSectionProps={{
        onCancel: onClose,
        onSubmit: () => onSubmit(formState),
        isLoading
      }}
      onClose={onClose}
      opened={isOpen}
      size={600}
      title={title}
    >
      <AppStack style={{ gap: 12 }}>
        {message && (
          <AppStack>
            {typeof message === 'string' ? (
              <AppText>{message}</AppText>
            ) : (
              message
            )}
          </AppStack>
        )}

        <CustomSelect
          data={resourceOptions || []}
          label={resourceName}
          onChange={(v) => setFormState({ resourceId: v })}
          placeholder={`Select ${resourceName?.toLowerCase()}`}
          value={formState.resourceId}
        />

        <AppText
          style={{
            fontSize: 14,
            color: '#666',
            textAlign: 'center',
            fontStyle: 'italic'
          }}
        >
          Please choose a {resourceName?.toLowerCase()} to duplicate the
          resources to.
        </AppText>

        {children}
      </AppStack>
    </ResponsiveModal>
  );
};

DuplicateResourcesModal.propTypes = {
  children: PropTypes.any,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  message: PropTypes.any,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  resourceName: PropTypes.string,
  resourceOptions: PropTypes.array,
  title: PropTypes.string
};

export default DuplicateResourcesModal;
