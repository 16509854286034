import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Columns } from 'tabler-icons-react';
import {
  ActionIcon,
  Button,
  Checkbox,
  Menu,
  ScrollArea,
  TextInput,
  Tooltip
} from '@mantine/core';
import AppMenu from '../../common/AppMenu';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';

const ReportTableColumnSelectMenu = ({
  displayedHeaderFields,
  onConfirm,
  reportHeaders
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [filterState, setFilterState] = useState({
    search: '',
    displayedHeaderFields: []
  });

  const fieldCategories =
    reportHeaders
      .filter(
        (f) =>
          !filterState.search ||
          f.label.toLowerCase().includes(filterState.search.toLowerCase())
      )
      .reduce((r, c) => {
        const existing = r.find((f) => f.label === c.category);
        if (!existing) {
          r.push({
            label: c.category,
            fields: [c]
          });
        }
        else {
          existing.fields.push(c);
        }
        return r;
      }, []) ?? [];
  const displayedHeadersCurrent = displayedHeaderFields.filter((f) =>
    reportHeaders.map((h) => h.value).includes(f)
  );

  const hasChanges =
    filterState.displayedHeaderFields.length !==
      displayedHeadersCurrent.length ||
    filterState.displayedHeaderFields.some(
      (f) => !displayedHeadersCurrent.includes(f)
    );
  const allFieldsSelected = fieldCategories.every((c) =>
    c.fields.every((f) => filterState.displayedHeaderFields.includes(f.value))
  );

  useEffect(() => {
    if (showMenu) {
      setFilterState({
        ...filterState,
        displayedHeaderFields: displayedHeadersCurrent
      });
    }
  }, [displayedHeaderFields, showMenu]);

  return (
    <AppMenu
      closeOnItemClick={false}
      control={
        <Tooltip label="Select columns" withArrow>
          <ActionIcon
            color="dark"
            size="md"
            style={{ border: 'solid 1px #b1b1b1' }}
            variant="subtle"
          >
            <Columns color="#999" size={22} />
          </ActionIcon>
        </Tooltip>
      }
      onClose={() => {
        setShowMenu(false);
      }}
      onOpen={() => {
        setShowMenu(true);
      }}
      opened={showMenu}
      width={300}
    >
      <AppStack style={{ gap: 5 }}>
        <AppFlexbox
          style={{
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <AppText style={{ fontSize: 14, fontWeight: 500 }}>
            Select columns
          </AppText>
          <AppFlexbox
            onClick={() => {
              setFilterState({
                ...filterState,
                displayedHeaderFields: allFieldsSelected
                  ? []
                  : reportHeaders.map((f) => f.value)
              });
            }}
            style={{
              gap: 5,
              cursor: filterState.fieldSearch ? 'not-allowed' : 'pointer',
              alignItems: 'center'
            }}
          >
            <Checkbox
              checked={!filterState.search && allFieldsSelected}
              disabled={!!filterState.search}
              onChange={() => {}}
              size="xs"
            />
            <AppText
              style={{
                fontSize: 14,
                color: filterState.search ? '#666' : '#000',
                fontWeight: 500
              }}
            >
              Select all
            </AppText>
          </AppFlexbox>
        </AppFlexbox>
        <TextInput
          onChange={(e) =>
            setFilterState({
              ...filterState,
              search: e.currentTarget.value
            })
          }
          placeholder="Search by column..."
          size="xs"
          value={filterState.search}
        />
        <Menu.Divider />

        {fieldCategories.length === 0 ? (
          <AppStack
            style={{
              padding: 40,
              alignItems: 'center',
              justifyContent: 'center',
              gap: 5
            }}
          >
            <AppText style={{ fontSize: 14, fontWeight: 500, color: '#666' }}>
              No columns found.
            </AppText>
            <Button
              color="dark"
              onClick={() =>
                setFilterState({
                  ...filterState,
                  search: ''
                })
              }
              size="compact-sm"
              type="buttons"
              variant="filled"
            >
              Clear search
            </Button>
          </AppStack>
        ) : (
          <ScrollArea h={450} offsetScrollbars scrollbarSize={8} type="auto">
            <AppStack
              style={{
                flex: 1,
                gap: 10
              }}
            >
              {fieldCategories.map((c, index) => {
                const allCategoryFieldsSelected = c.fields.every((f) =>
                  filterState.displayedHeaderFields.includes(f.value)
                );
                return (
                  <React.Fragment key={c.label}>
                    {index > 0 && <Menu.Divider />}
                    <AppStack style={{ gap: 0 }}>
                      {fieldCategories.length > 1 && (
                        <AppFlexbox
                          style={{
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '5px 12px'
                          }}
                        >
                          <AppText
                            style={{
                              fontSize: 16,
                              fontWeight: 500
                            }}
                          >
                            {c.label}
                          </AppText>
                          {!filterState.search && (
                            <AppFlexbox
                              onClick={() => {
                                setFilterState({
                                  ...filterState,
                                  displayedHeaderFields: allCategoryFieldsSelected
                                    ? filterState.displayedHeaderFields.filter(
                                        (f) =>
                                          !c.fields
                                            .map((m) => m.value)
                                            .includes(f)
                                      )
                                    : [
                                        ...filterState.displayedHeaderFields.filter(
                                          (f) =>
                                            !c.fields
                                              .map((m) => m.value)
                                              .includes(f)
                                        ),
                                        ...c.fields.map((f) => f.value)
                                      ]
                                });
                              }}
                              style={{
                                gap: 5,
                                cursor: 'pointer',
                                alignItems: 'center'
                              }}
                            >
                              <Checkbox
                                checked={
                                  !filterState.search &&
                                  allCategoryFieldsSelected
                                }
                                onChange={() => {}}
                                size="xs"
                              />
                              <AppText
                                style={{
                                  fontSize: 13,
                                  fontWeight: 500
                                }}
                              >
                                Select all
                              </AppText>
                            </AppFlexbox>
                          )}
                        </AppFlexbox>
                      )}

                      {c.fields.map((f) => (
                        <Menu.Item
                          key={f.value}
                          onClick={(e) => {
                            e.preventDefault();
                            setFilterState({
                              ...filterState,
                              displayedHeaderFields: filterState.displayedHeaderFields.includes(
                                f.value
                              )
                                ? filterState.displayedHeaderFields.filter(
                                    (v) => v !== f.value
                                  )
                                : [
                                    ...filterState.displayedHeaderFields,
                                    f.value
                                  ]
                            });
                          }}
                        >
                          <AppFlexbox style={{ alignItems: 'center', gap: 12 }}>
                            <Checkbox
                              checked={filterState.displayedHeaderFields.includes(
                                f.value
                              )}
                              onChange={() => {}}
                            />
                            <AppText style={{ fontSize: 14 }}>
                              {f.label}
                            </AppText>
                          </AppFlexbox>
                        </Menu.Item>
                      ))}
                    </AppStack>
                  </React.Fragment>
                );
              })}
            </AppStack>
          </ScrollArea>
        )}
        {hasChanges && (
          <AppFlexbox
            style={{
              padding: 5,
              alignItems: 'center',
              justifyContent: 'end',
              borderTop: 'solid 1px #dee2e6'
            }}
          >
            <Button
              color="dark"
              onClick={() => {
                onConfirm([
                  ...reportHeaders
                    .filter(
                      (f) =>
                        (!f.chartable &&
                          displayedHeaderFields.includes(f.value)) ||
                        filterState.displayedHeaderFields.includes(f.value)
                    )
                    .map((f) => f.value)
                ]);
                setShowMenu(false);
              }}
              size="compact-sm"
              type="button"
              variant="filled"
            >
              Apply
            </Button>
          </AppFlexbox>
        )}
      </AppStack>
    </AppMenu>
  );
};

ReportTableColumnSelectMenu.propTypes = {
  displayedHeaderFields: PropTypes.array,
  onConfirm: PropTypes.func,
  reportHeaders: PropTypes.array
};

export default ReportTableColumnSelectMenu;
