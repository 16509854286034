import React, { useContext, useEffect, useRef } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Alert, Button, Divider } from '@mantine/core';
import { AlertCircle } from 'tabler-icons-react';
import VendorCatalogExplorer from './VendorCatalogExplorer';
import VendorCatalogCategoryView from './VendorCatalogCategoryView';
import VendorCatalogCategoriesView from './VendorCatalogCategoriesView';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as HelperContext } from '../../../providers/HelperContextProvider';
import VendorCatalogVendorsView from './VendorCatalogVendorsView';
import VendorCatalogVendorView from './VendorCatalogVendorView';
import VendorCatalogProductView from './VendorCatalogProductView';
import SelectedVendorProductsDialog from './SelectedVendorProductsDialog';
import VendorCatalogProductSortExplorer from './VendorCatalogProductSortExplorer';
import { useVendorCatalogCheckout } from '../../../helpers/vendorCatalogCheckoutHelper';
import VendorCatalogRemoveStoreProductsModal from './VendorCatalogRemoveStoreProductsModal';
import AppStack from '../../common/AppStack';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';

const VendorCatalogView = ({ vendorPreview }) => {
  const hasFetched = useRef(false);
  const { pathname } = useLocation();
  const { state: authState } = useContext(AuthContext);
  const {
    state,
    fetchStoreCatalogVendors,
    fetchStoreCatalogProductsSummary,
    fetchStoreProductOptions,
    fetchStoreSetupSummary
  } = useContext(StoreContext);
  const {
    state: helperState,
    toggleVendorCatalogDrawer,
    toggleVendorCatalogDemoWarning,
    toggleVendorCatalogDemoMode
  } = useContext(HelperContext);
  const { cart, fetchCart: fetchCatalogCart } = useVendorCatalogCheckout();
  const loading =
    !hasFetched.current ||
    state.catalogVendors.loading ||
    state.ecomStoreProducts.loading ||
    state.catalogCartProducts.loading;
  const baseUrl = vendorPreview
    ? '/vendor/catalog'
    : '/merchant/products/discover';

  useEffect(() => {
    if (
      helperState.vendorCatalog.initialized &&
      (authState.pkEcomStore || authState.pkEcomVendor)
    ) {
      if (!state.catalogVendors.filter) {
        fetchStoreCatalogVendors({
          pkEcomStore: vendorPreview ? null : authState.pkEcomStore,
          pkEcomVendor: vendorPreview ? authState.pkEcomVendor : null
        });
      }

      if (!hasFetched.current) {
        fetchStoreProductOptions(authState.pkEcomStore, {});
        if (!vendorPreview) {
          fetchStoreSetupSummary(authState.pkEcomStore, { skipLoading: true });
        }
      }

      if (!hasFetched.current) {
        fetchStoreProductOptions(authState.pkEcomStore, {});
        if (!vendorPreview) {
          fetchStoreSetupSummary(authState.pkEcomStore, { skipLoading: true });
        }
      }

      if (
        state.catalogVendors.filter?.isDemoMode !==
        helperState.vendorCatalog.isDemoMode
      ) {
        fetchStoreCatalogProductsSummary({
          pkEcomStore: vendorPreview ? null : authState.pkEcomStore,
          pkEcomVendor: vendorPreview ? authState.pkEcomVendor : null,
          isDemoMode: helperState.vendorCatalog.isDemoMode
        });

        if (!vendorPreview && cart.storageCart) {
          fetchCatalogCart();
        }
        toggleVendorCatalogDrawer(
          cart.storageCart && cart.storageCart.ecomVendorProducts.length > 0
        );
      }
      hasFetched.current = true;
    }
  }, [
    authState.pkEcomStore,
    authState.pkEcomVendor,
    helperState.vendorCatalog.initialized,
    helperState.vendorCatalog.isDemoMode
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (
      helperState.vendorCatalog.initialized &&
      state.ecomStoreSetupSummary.value &&
      !helperState.vendorCatalog.hasInitializedDemoWarning
    ) {
      toggleVendorCatalogDemoWarning(
        helperState.vendorCatalog.isDemoMode !==
          state.ecomStoreSetupSummary.value.ecomStore.isDemoStore
      );
    }
  }, [
    helperState.vendorCatalog.initialized,
    helperState.vendorCatalog.isDemoMode,
    state.ecomStoreSetupSummary.value
  ]);

  return (
    <>
      <Routes>
        <Route
          element={<VendorCatalogCategoriesView baseUrl={baseUrl} />}
          path="/categories"
        />
        <Route
          element={
            <VendorCatalogCategoryView
              baseUrl={baseUrl}
              loading={loading}
              vendorPreview={vendorPreview}
            />
          }
          path="/categories/:categoryName"
        />
        <Route
          element={
            <VendorCatalogProductSortExplorer
              baseUrl={baseUrl}
              loading={loading}
              vendorPreview={vendorPreview}
            />
          }
          path="/products"
        />
        <Route
          element={
            <VendorCatalogProductSortExplorer
              baseUrl={baseUrl}
              loading={loading}
              vendorPreview={vendorPreview}
            />
          }
          path="/products/:sort"
        />
        <Route
          element={
            <VendorCatalogProductView
              baseUrl={baseUrl}
              loading={loading}
              vendorPreview={vendorPreview}
            />
          }
          path="/products/:ecomVendor/:urlHandle"
        />
        <Route
          element={
            <VendorCatalogVendorsView baseUrl={baseUrl} loading={loading} />
          }
          path="/vendors"
        />
        <Route
          element={
            <VendorCatalogVendorView
              baseUrl={baseUrl}
              vendorPreview={vendorPreview}
            />
          }
          path="/vendors/:ecomVendor"
        />
        <Route
          element={
            <VendorCatalogVendorView
              baseUrl={baseUrl}
              vendorPreview={vendorPreview}
            />
          }
          path="/vendors/:ecomVendor/collections/:collectionName"
        />
        <Route
          element={
            <VendorCatalogExplorer
              baseUrl={baseUrl}
              loading={loading}
              vendorPreview={vendorPreview}
            />
          }
          path="/"
        />
        <Route element={<Navigate replace to={baseUrl} />} path="*" />
      </Routes>
      <SelectedVendorProductsDialog />
      <VendorCatalogRemoveStoreProductsModal />

      <ResponsiveModal
        onClose={() => toggleVendorCatalogDemoWarning(false)}
        opened={
          helperState.vendorCatalog.showDemoWarning &&
          state.ecomStoreSetupSummary.value
        }
        size={500}
        title={`
          Viewing ${
            helperState.vendorCatalog.isDemoMode ? 'demo' : 'live'
          } catalog as ${
          state.ecomStoreSetupSummary.value?.ecomStore.isDemoStore
            ? 'demo'
            : 'live'
        } store
          `}
      >
        {state.ecomStoreSetupSummary.value && (
          <AppStack style={{ gap: 24, padding: 20 }}>
            <Alert color="#eca70a" variant="outline">
              <AppFlexbox style={{ gap: 8 }}>
                <AppStack>
                  <AlertCircle
                    color="#eca70a"
                    size={22}
                    style={{ marginTop: 3 }}
                  />
                </AppStack>
                <AppStack style={{ gap: 5 }}>
                  <AppText
                    style={{
                      fontSize: 16,
                      fontWeight: 500
                    }}
                  >
                    You are viewing the{' '}
                    {helperState.vendorCatalog.isDemoMode ? 'demo' : 'live'}{' '}
                    catalog as a{' '}
                    {state.ecomStoreSetupSummary.value.ecomStore.isDemoStore
                      ? 'demo'
                      : 'live'}{' '}
                    store.
                  </AppText>

                  <AppText style={{ fontSize: 14, color: '#666' }}>
                    Please switch to the{' '}
                    {helperState.vendorCatalog.isDemoMode ? 'live' : 'demo'}{' '}
                    catalog before adding products.
                  </AppText>
                </AppStack>
              </AppFlexbox>
            </Alert>

            <Button
              color="dark"
              onClick={() => {
                toggleVendorCatalogDemoMode(
                  authState.pkEcomStore,
                  !helperState.vendorCatalog.isDemoMode
                );
              }}
            >
              Switch to {helperState.vendorCatalog.isDemoMode ? 'live' : 'demo'}{' '}
              catalog
            </Button>

            <Divider label="OR" labelPosition="center" />

            <Button
              color="dark"
              onClick={() => {
                toggleVendorCatalogDemoWarning(false);
              }}
              variant="outline"
            >
              Continue in{' '}
              {helperState.vendorCatalog.isDemoMode ? 'demo' : 'live'} mode
            </Button>
          </AppStack>
        )}
      </ResponsiveModal>
    </>
  );
};

VendorCatalogView.propTypes = { vendorPreview: PropTypes.bool };

export default VendorCatalogView;
