import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ActionIcon, Badge, Select } from '@mantine/core';
import { Trash, X } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import {
  REPORT_FIELD_COMPARE_OPTIONS,
  REPORT_FIELD_LOGIC_OPERATORS,
  REPORT_HEADER_TYPE_ENUM
} from './reportsConfig';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const ReportFilterInput = ({
  filterState,
  onStateChange,
  onRemove,
  fieldOptionsGroupedByCategory,
  reportHeaders
}) => {
  const { isLargeMobileOrSmaller } = useMediaQueryIndex();
  const [searchState, setSearchState] = useState('');

  const reportHeader = reportHeaders.find((r) => r.value === filterState.field);
  const allowedLogicOperators = REPORT_FIELD_LOGIC_OPERATORS.filter(
    (s) =>
      !s.blockedBounds ||
      !filterState.compare ||
      !s.blockedBounds.includes(filterState.compare)
  );
  const allowedCompareOptions = REPORT_FIELD_COMPARE_OPTIONS.filter(
    (c) => !c.bounds || !reportHeader || c.bounds.includes(reportHeader.type)
  );

  const filterOptions =
    reportHeader?.type === REPORT_HEADER_TYPE_ENUM.BOOLEAN
      ? [
          {
            label: 'Yes',
            value: 'Yes'
          },
          {
            label: 'No',
            value: 'No'
          }
        ]
      : reportHeader?.options || [];

  const onChangeFilterField = (fieldLabel) => {
    const newField = reportHeaders.find((r) => r.label === fieldLabel);
    if (newField) {
      const newAllowedCompareOptions = REPORT_FIELD_COMPARE_OPTIONS.filter(
        (c) => !c.bounds || c.bounds.includes(newField.type)
      );
      const compare =
        newAllowedCompareOptions.find((l) => l.value === filterState.compare)
          ?.value || newAllowedCompareOptions[0].value;
      const newAllowedLogicOperators = REPORT_FIELD_LOGIC_OPERATORS.filter(
        (s) =>
          !s.blockedBounds || !compare || !s.blockedBounds.includes(compare)
      );

      onStateChange({
        ...filterState,
        field: newField.value,
        compare,
        logicalOperator:
          newAllowedLogicOperators.find(
            (l) => l.value === filterState.logicalOperator
          )?.value || newAllowedLogicOperators[0]?.value
      });
    }
  };

  const onCompareChange = (compare) => {
    const newAllowedLogicOperators = REPORT_FIELD_LOGIC_OPERATORS.filter(
      (s) => !s.blockedBounds || !compare || !s.blockedBounds.includes(compare)
    );

    onStateChange({
      ...filterState,
      compare,
      logicalOperator:
        newAllowedLogicOperators.find(
          (l) => l.value === filterState.logicalOperator
        )?.value || newAllowedLogicOperators[0]?.value
    });
  };

  const onAddFilterFieldValue = (value) => {
    if (value) {
      setSearchState({
        ...searchState,
        value: ''
      });

      if (
        (reportHeader?.type === REPORT_HEADER_TYPE_ENUM.CURRENCY ||
          reportHeader?.type === REPORT_HEADER_TYPE_ENUM.NUMBER) &&
        Number.isNaN(Number(value))
      ) {
        return;
      }

      if (
        filterOptions.length > 0 &&
        !filterOptions.find((v) => v.value === value)
      ) {
        return;
      }

      if (!filterState.values.includes(value)) {
        onStateChange({
          ...filterState,
          values: [...filterState.values, value]
        });
      }
    }
  };

  const onRemoveFilterFieldValue = (value) => {
    onStateChange({
      ...filterState,
      values: filterState.values.filter((v) => v !== value)
    });
  };

  const onChangeLogicalOperator = (logicalOperator) => {
    onStateChange({
      ...filterState,
      logicalOperator
    });
  };

  return (
    <AppStack style={{ padding: 16, gap: 8 }}>
      <AppFlexbox style={{ gap: 8, alignItems: 'center', flex: 1 }}>
        <AppFlexbox style={{ flex: 1 }}>
          <Select
            data={fieldOptionsGroupedByCategory.map((c) => ({
              group: c.label,
              items: c.fields.map((v) => v.label)
            }))}
            onChange={onChangeFilterField}
            placeholder="Select filter"
            searchable
            size="sm"
            style={{ flex: 2, fontSize: 16 }}
            value={reportHeader?.label}
          />
        </AppFlexbox>
        <AppFlexbox
          style={{
            gap: 8,
            alignItems: 'center',
            flex: isLargeMobileOrSmaller ? 1 : 'unset'
          }}
        >
          <Select
            data={allowedCompareOptions}
            onChange={onCompareChange}
            size="sm"
            style={{ maxWidth: isLargeMobileOrSmaller ? 'unset' : 175 }}
            value={filterState.compare}
          />
          <ActionIcon
            color="dark"
            onClick={() => onRemove(filterState.key)}
            size="lg"
            variant="outline"
          >
            <Trash />
          </ActionIcon>
        </AppFlexbox>
      </AppFlexbox>

      <AppFlexbox style={{ gap: 8, alignItems: 'center', flex: 1 }}>
        <AppFlexbox style={{ flex: 1 }}>
          <Select
            data={filterOptions}
            disabled={!filterState.compare || !reportHeader}
            nothingFoundMessage={
              reportHeader?.type !== REPORT_HEADER_TYPE_ENUM.BOOLEAN
                ? 'Press enter to add value'
                : ''
            }
            onBlur={() => onAddFilterFieldValue(searchState.value)}
            onChange={(v) => {
              onAddFilterFieldValue(v);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                e.stopPropagation();

                onAddFilterFieldValue(searchState.value);
              }
            }}
            onSearchChange={(v) =>
              setSearchState({
                ...searchState,
                filterKey: filterState.key,
                value: v
              })
            }
            placeholder="value"
            searchable
            searchValue={
              searchState.filterKey === filterState.key ? searchState.value : ''
            }
            size="sm"
            style={{ flex: 1 }}
            value=""
          />
        </AppFlexbox>
        <AppFlexbox
          style={{
            gap: 8,
            alignItems: 'center',
            flex: isLargeMobileOrSmaller ? 1 : 'unset'
          }}
        >
          <Select
            data={allowedLogicOperators}
            disabled={!reportHeader || allowedLogicOperators.length <= 1}
            onChange={onChangeLogicalOperator}
            size="sm"
            style={{ maxWidth: isLargeMobileOrSmaller ? 'unset' : 175 }}
            value={filterState.logicalOperator}
          />
          <AppStack style={{ minWidth: 34 }} />
        </AppFlexbox>
      </AppFlexbox>
      <AppFlexbox style={{ gap: 8, flexWrap: 'wrap' }}>
        {filterState.values.map((value) => (
          <Badge
            key={value}
            color="grey"
            rightSection={
              <ActionIcon
                color="dark"
                onClick={() => onRemoveFilterFieldValue(value)}
                variant="subtle"
              >
                <X size={16} />
              </ActionIcon>
            }
            styles={{
              label: { textTransform: 'none' },
              root: { padding: '0px 5px 0px 15px' }
            }}
            variant="light"
          >
            {filterState.compare} {value}
          </Badge>
        ))}
      </AppFlexbox>
    </AppStack>
  );
};

ReportFilterInput.propTypes = {
  fieldOptionsGroupedByCategory: PropTypes.array,
  filterState: PropTypes.object,
  onRemove: PropTypes.func,
  onStateChange: PropTypes.func,
  reportHeaders: PropTypes.array
};

export default ReportFilterInput;
