import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  AlertCircle,
  ArrowLeft,
  ChevronDown,
  Dots,
  Download,
  Send
} from 'tabler-icons-react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Loader,
  Menu,
  NumberFormatter,
  Select,
  Skeleton,
  Textarea,
  TextInput
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import dayjs from 'dayjs';
import { Context as AdminContext } from '../../../providers/AdminContextProvider';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import { useMediaQueryIndex, useModalState } from '../../../helpers/hooks';
import AdminVendorInfoCard from './AdminVendorInfoCard';
import AdminVendorPaymentProviderInfoCard from './AdminVendorPaymentProviderInfoCard';
import ActionableIcon from '../../common/ActionableIcon';
import AppCard from '../../common/AppCard';
import AppMenu from '../../common/AppMenu';
import { triggerNotification } from '../../../helpers/notification';
import AdminVendorPayoutListItem from './AdminVendorPayoutListItem';
import { usePrompt } from '../../../helpers/prompt';
import ConfirmModal from '../../common/ConfirmModal';
import AdminVendorPayoutSummary from './AdminVendorPayoutSummary';
import { formatUtcDate } from '../../../helpers/format';
import { getAdminBackPath } from '../../../helpers/adminHelper';
import CustomTooltip from '../../common/CustomTooltip';
import AppCheckbox from '../../common/AppCheckbox';
import { VIEW_ACTIONS_ENUM } from '../../../config/constants';
import { downloadPapaCsv } from '../../../helpers/csvHelper';

const PAYOUT_REASONS = ['Payment for goods', 'Other'];

const AdminVendorPayoutView = ({ loading, isCreate }) => {
  const hasInitialized = useRef(false);
  const navigate = useNavigate();
  const { pkEcomVendor, pkEcomVendorPayout } = useParams();
  const { isLargeMobileOrSmaller, isDesktopOrSmaller } = useMediaQueryIndex();
  const backPath = getAdminBackPath(`/admin/vendors/${pkEcomVendor}`);
  const {
    state,
    fetchEcomVendor,
    fetchEcomVendorPayout,
    createEcomVendorPayout
  } = useContext(AdminContext);
  const [filterState, setFilterState] = useState({
    startDate: null,
    endDate: null,
    changeRequest: null
  });
  const [exportState, setExportState] = useState({ expandProductData: false });
  const [formState, setFormState] = useState({
    chequeId: '',
    reason: PAYOUT_REASONS[0],
    description: '',
    pkEcomOrders: [],
    loading: false,
    showConfirm: false,
    hasUnsavedChanges: false,
    hasProductChanges: false
  });
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();

  const ecomVendorDetails = state.ecomVendor.value;
  const { ecomVendorPayout, ecomVendorPayoutBalance, ecomOrderProducts } =
    state.ecomVendorPayout.value || {};

  const viewLoading =
    !hasInitialized.current || loading || state.ecomVendorPayout.loading;
  const controlsDisabled = loading || formState.loading;

  const ecomOrders =
    ecomVendorPayoutBalance?.ecomOrders
      .filter(
        (f) =>
          !isCreate || f.payoutEarned + f.payoutLocked - f.payoutReceived !== 0
      )
      .reduce(
        (r, c) => [
          ...r,
          {
            ...c,
            ecomOrderProducts: c.ecomOrderProducts.map((p) => ({
              ...p,
              ...ecomOrderProducts.find(
                (e) => e.pkEcomOrderProduct === p.pkEcomOrderProduct
              )
            }))
          }
        ],
        []
      ) || [];
  const selectedOrders = ecomOrders.filter(
    (e) => !isCreate || formState.pkEcomOrders.includes(e.pkEcomOrder)
  );
  const payoutAmount =
    selectedOrders.reduce(
      (r, c) => r + (c.payoutEarned - c.payoutReceived),
      0
    ) / 100;
  const amountRefunded =
    selectedOrders.reduce((r, c) => r + c.amountRefunded, 0) / 100;
  const payoutLocked =
    selectedOrders.reduce((r, c) => r + c.payoutLocked, 0) / 100;
  const offlineAmount =
    selectedOrders.reduce((r, c) => r + c.amountPaidOffline, 0) / 100;

  const payoutPayments = ecomVendorPayout?.ecomOrderProductPayments || [];
  const payoutReceived =
    (isCreate
      ? selectedOrders.reduce((r, c) => r + c.payoutReceived, 0)
      : payoutPayments.reduce((r, c) => r + c.payoutAmount, 0)) / 100;

  const allOrdersSelected = selectedOrders.length === ecomOrders.length;

  usePrompt(
    'Are you sure you want to leave this page? You have unsaved changes.',
    formState.hasUnsavedChanges && !formState.loading
  );

  useEffect(() => {
    if (ecomVendorDetails) {
      fetchEcomVendorPayout(
        ecomVendorDetails.ecomVendor.pkEcomVendor,
        {
          pkEcomVendorPayout: isCreate ? null : pkEcomVendorPayout,
          startDate: filterState.startDate,
          endDate: filterState.endDate
        },
        null,
        (e) => {
          triggerNotification(e);
        }
      );
    }
  }, [ecomVendorDetails, filterState.startDate, filterState.endDate]);

  useEffect(() => {
    if (
      state.ecomVendorPayout.value &&
      ecomVendorPayoutBalance?.ecomVendor.pkEcomVendor ===
        ecomVendorDetails?.ecomVendor.pkEcomVendor
    ) {
      setFormState({
        ...formState,
        chequeId: ecomVendorPayout?.chequeId || '',
        reason: ecomVendorPayout
          ? PAYOUT_REASONS.find((r) => r === ecomVendorPayout?.description) ||
            'Other'
          : PAYOUT_REASONS[0],
        description: ecomVendorPayout?.description || '',
        pkEcomOrders:
          ecomVendorPayoutBalance?.ecomOrders.map((e) => e.pkEcomOrder) || [],
        loading: false,
        showConfirm: false,
        hasUnsavedChanges: false,
        hasProductChanges: false
      });
      hasInitialized.current = true;
    }
  }, [state.ecomVendorPayout.value]);

  const onSubmitPayout = () => {
    if (isCreate) {
      setFormState({
        ...formState,
        loading: true
      });
      createEcomVendorPayout(
        ecomVendorDetails.ecomVendor.pkEcomVendor,
        {
          chequeId: formState.chequeId,
          description:
            formState.reason === 'Other'
              ? formState.description
              : formState.reason,
          pkEcomOrders: selectedOrders
            .filter((f) => f.payoutEarned - f.payoutReceived !== 0)
            .map((e) => e.pkEcomOrder),
          payoutAmount: selectedOrders.reduce(
            (r, c) => r + (c.payoutEarned - c.payoutReceived),
            0
          )
        },
        () => {
          triggerNotification('Payout submitted successfully.', 'success');
          fetchEcomVendor(pkEcomVendor);
          navigate(`/admin/vendors/${pkEcomVendor}`);
        },
        (e) => {
          triggerNotification(e);
          setFormState({
            ...formState,
            loading: false
          });
        }
      );
    }
  };

  const onExportPayoutDetails = () => {
    const exportColumns = [
      'Order ID',
      'Purchase date',
      'Total order value',
      'Total amount refunded',
      'Total paid offline',
      'Total amount locked',
      'Total earned',
      'Total received',
      'Total Balance remaining',

      ...(exportState.expandProductData
        ? [
            'Product title',
            'Product quantity',
            'Product bulk quantity',
            'Product cost',
            'Product cost total',
            'Product cost earned'
          ]
        : ['Products'])
    ];

    let rowData = [];
    if (exportState.expandProductData) {
      rowData = ecomOrders.reduce((r, c) => {
        const orderData = [
          c.pkEcomOrder,
          dayjs(c.purchasedAt).format('MMMM D, YYYY h:mm A'),
          c.amountPaid / 100,
          c.amountRefunded / 100,
          c.amountPaidOffline / 100,
          c.payoutLocked / 100,
          c.payoutEarned / 100,
          c.payoutReceived / 100,
          (c.payoutEarned - c.payoutReceived) / 100
        ];

        const productData = c.ecomOrderProducts.map((p) => [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          `${p.productName}${
            p.variantOptions.length > 0
              ? ` (${p.variantOptions.map((a) => a.value).join(' / ')})`
              : ''
          }`,
          p.count,
          p.isBulkShipment ? p.bulkCount : '',
          p.productCost / 100,
          ((p.productCost * p.count) / 100).toFixed(2),
          (p.productCost * (p.isBulkShipment ? p.bulkCount : p.count)) / 100
        ]);

        return [...r, orderData, ...productData];
      }, []);
    }
    else {
      rowData = ecomOrders.map((c) => [
        c.pkEcomOrder,
        dayjs(c.purchasedAt).format('MMMM D, YYYY h:mm A'),
        c.amountPaid / 100,
        c.amountRefunded / 100,
        c.amountPaidOffline / 100,
        c.payoutLocked / 100,
        c.payoutEarned / 100,
        c.payoutReceived / 100,
        (c.payoutEarned - c.payoutReceived) / 100,
        c.ecomOrderProducts
          .map(
            (p) =>
              `${p.count} x ${p.productName}${
                p.variantOptions.length > 0
                  ? ` (${p.variantOptions.map((a) => a.value).join(' / ')})`
                  : ''
              }`
          )
          .join('\n')
      ]);
    }

    downloadPapaCsv(
      `${ecomVendorDetails.ecomVendor.name}_payout_details_${dayjs().format(
        'MMMM/DD/YYYY'
      )}`,
      exportColumns,
      rowData
    );
  };

  return !viewLoading && ecomVendorDetails ? (
    <AppStack
      p={{ base: 8, sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox
        style={{
          alignItems: 'start',
          justifyContent: 'space-between',
          gap: 8
        }}
      >
        <AppFlexbox style={{ gap: 8 }}>
          <ActionableIcon
            color="dark"
            component={Link}
            radius="md"
            style={{ marginTop: 5 }}
            to={backPath}
            variant="subtle"
          >
            <ArrowLeft />
          </ActionableIcon>
          {viewLoading ? (
            <AppStack style={{ gap: 5 }}>
              <Skeleton height={35} width="20%" />
              <Skeleton height={14} width="40%" />
            </AppStack>
          ) : (
            <AppStack style={{ gap: 0 }}>
              <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
                <AppText
                  style={{
                    flex: 1,
                    fontSize: 24,
                    fontWeight: 700,
                    wordBreak: 'break-word'
                  }}
                >
                  {isCreate ? 'Create a new Payout' : 'Payout details'}
                </AppText>
              </AppFlexbox>

              <AppText
                style={{
                  fontSize: 12,
                  lineHeight: '16px',
                  color: '#666'
                }}
              >
                {ecomVendorPayout
                  ? `Created ${dayjs(
                      formatUtcDate(ecomVendorPayout?.createdAt)
                    ).format('MMMM D, YYYY')} at ${dayjs(
                      formatUtcDate(ecomVendorPayout?.createdAt)
                    ).format('h:mm A')}`
                  : `Create payout `}{' '}
                for {ecomVendorDetails.ecomVendor.name}
              </AppText>
            </AppStack>
          )}
        </AppFlexbox>

        {ecomVendorDetails && (
          <AppFlexbox style={{ alignItems: 'center', gap: 8, marginTop: 5 }}>
            <AppMenu
              control={
                <Button
                  color="dark"
                  rightSection={<ChevronDown size={18} />}
                  size="compact-md"
                  style={{ fontSize: 14 }}
                  variant="light"
                >
                  Actions
                </Button>
              }
            >
              <Menu.Item
                leftSection={<Download size={18} />}
                onClick={() => onOpenModal(VIEW_ACTIONS_ENUM.EXPORT)}
              >
                Export details
              </Menu.Item>
              <Menu.Item
                component="a"
                href={`mailto:${ecomVendorDetails.ecomVendor.supportEmail}`}
                leftSection={<Send size={18} />}
              >
                Email vendor
              </Menu.Item>
            </AppMenu>
          </AppFlexbox>
        )}
      </AppFlexbox>
      <AppFlexbox
        component={isCreate ? 'form' : null}
        onSubmit={
          isCreate
            ? (e) => {
                e.preventDefault();
                setFormState({
                  ...formState,
                  showConfirm: true
                });
              }
            : null
        }
        style={{
          flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row',
          width: '100%'
        }}
      >
        <AppStack style={{ flex: 1, width: '100%' }}>
          <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
            <AppStack style={{ gap: 0 }}>
              <AppStack style={{ gap: 8, padding: 16 }}>
                <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                  Payout
                </AppText>

                <AppFlexbox
                  style={{
                    gap: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <AppText style={{ fontSize: 14 }}>
                    Orders {ecomVendorPayout ? 'included' : 'selected'}
                  </AppText>
                  {viewLoading ? (
                    <Skeleton height={14} width={50} />
                  ) : (
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      {selectedOrders.length}
                    </AppText>
                  )}
                </AppFlexbox>

                {isCreate && (
                  <>
                    <AppFlexbox
                      style={{
                        gap: 8,
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <AppText style={{ fontSize: 14 }}>Amount earned</AppText>
                      <NumberFormatter
                        decimalScale={2}
                        fixedDecimalScale
                        prefix="$"
                        style={{ fontSize: 14 }}
                        thousandSeparator
                        value={payoutAmount + payoutLocked + amountRefunded}
                      />
                    </AppFlexbox>
                    {amountRefunded > 0 && (
                      <AppFlexbox
                        style={{
                          gap: 8,
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <AppText style={{ fontSize: 14, color: '#C40000' }}>
                          Amount refunded
                        </AppText>
                        <NumberFormatter
                          allowNegative={false}
                          decimalScale={2}
                          fixedDecimalScale
                          prefix="-$"
                          style={{ fontSize: 14, color: '#C40000' }}
                          thousandSeparator
                          value={amountRefunded}
                        />
                      </AppFlexbox>
                    )}
                    {offlineAmount > 0 && (
                      <AppFlexbox
                        style={{
                          gap: 5,
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <AppText style={{ fontSize: 14, color: '#C40000' }}>
                          Offline amount
                        </AppText>
                        <NumberFormatter
                          allowNegative={false}
                          decimalScale={2}
                          fixedDecimalScale
                          prefix="-$"
                          style={{ fontSize: 14, color: '#C40000' }}
                          thousandSeparator
                          value={offlineAmount}
                        />
                      </AppFlexbox>
                    )}
                    {payoutLocked > 0 && (
                      <AppFlexbox
                        style={{
                          gap: 5,
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <CustomTooltip
                          label="Payouts may be locked when group orders are in the 'accumulating' stage."
                          width={300}
                        >
                          <AppFlexbox
                            style={{
                              gap: 8,
                              alignItems: 'center'
                            }}
                          >
                            <AppText style={{ fontSize: 14, color: '#C40000' }}>
                              Amount locked
                            </AppText>
                            <AlertCircle color="#C40000" size={18} />
                          </AppFlexbox>
                        </CustomTooltip>
                        <NumberFormatter
                          allowNegative={false}
                          decimalScale={2}
                          fixedDecimalScale
                          prefix="-$"
                          style={{ fontSize: 14, color: '#C40000' }}
                          thousandSeparator
                          value={payoutLocked}
                        />
                      </AppFlexbox>
                    )}

                    <Divider />
                  </>
                )}

                <AppFlexbox
                  style={{
                    gap: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <AppText style={{ fontSize: 14 }}>
                    {isCreate ? 'Total amount to be paid' : 'Payout received'}
                  </AppText>
                  {viewLoading ? (
                    <Skeleton height={14} width={50} />
                  ) : (
                    <NumberFormatter
                      decimalScale={2}
                      fixedDecimalScale
                      prefix="$"
                      style={{ fontSize: 14, fontWeight: 500 }}
                      thousandSeparator
                      value={isCreate ? payoutAmount : payoutReceived}
                    />
                  )}
                </AppFlexbox>
              </AppStack>
              {isCreate && (
                <>
                  <Divider />
                  <AppStack style={{ gap: 8, padding: 16 }}>
                    <AppFlexbox
                      style={{
                        gap: 8,
                        flexDirection: isLargeMobileOrSmaller ? 'column' : 'row'
                      }}
                    >
                      <TextInput
                        disabled={controlsDisabled || !isCreate}
                        label="Cheque ID (optional)"
                        onChange={(e) =>
                          setFormState({
                            ...formState,
                            chequeId: e.currentTarget.value,
                            hasUnsavedChanges: true
                          })
                        }
                        placeholder={isCreate ? 'Enter cheque ID' : 'N/A'}
                        style={{ flex: 1 }}
                        value={formState.chequeId}
                      />

                      <Select
                        clearable
                        data={PAYOUT_REASONS.map((p) => ({
                          value: p,
                          label: p
                        }))}
                        disabled={controlsDisabled || !isCreate}
                        label="Reason"
                        onChange={(v) =>
                          setFormState({
                            ...formState,
                            reason: v,
                            hasUnsavedChanges: true
                          })
                        }
                        required
                        searchable
                        style={{ flex: 1 }}
                        value={formState.reason}
                      />
                    </AppFlexbox>

                    {formState.reason === 'Other' && (
                      <Textarea
                        autosize
                        description="Enter custom reason or description for this payout"
                        disabled={controlsDisabled || !isCreate}
                        label="Custom reason"
                        min={3}
                        onChange={(e) =>
                          setFormState({
                            ...formState,
                            description: e.currentTarget.value,
                            hasUnsavedChanges: true
                          })
                        }
                        required
                        value={formState.description}
                      />
                    )}
                  </AppStack>
                  <Divider />
                  <AppStack style={{ padding: '8px 16px' }}>
                    <AppText style={{ fontSize: 14, color: '#999' }}>
                      You'll receive a confirmation email once your payout is
                      successfully submitted.
                    </AppText>
                  </AppStack>
                </>
              )}
            </AppStack>
          </AppCard>

          <AppCard
            hiddenFrom="lg"
            radius="md"
            shadow="xs"
            style={{ padding: 0 }}
            withBorder
          >
            <AppStack style={{ gap: 0 }}>
              <AppStack style={{ gap: 8, padding: 16 }}>
                <AppStack style={{ fontSize: 16, fontWeight: 500 }}>
                  Summary
                </AppStack>

                <AdminVendorPayoutSummary
                  chequeId={formState.chequeId}
                  fontSize={14}
                  loading={viewLoading}
                  payoutAmount={payoutAmount}
                  reason={formState.reason}
                  totalOrderCount={selectedOrders.length}
                />
              </AppStack>
              {isCreate && (
                <>
                  <Divider />
                  <AppStack style={{ gap: 0, padding: 16 }}>
                    <Button
                      color="dark"
                      disabled={controlsDisabled || payoutAmount === 0}
                      type="submit"
                      variant="filled"
                    >
                      Submit Payout
                    </Button>
                  </AppStack>
                </>
              )}
            </AppStack>
          </AppCard>

          <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
            <AppStack style={{ gap: 0 }}>
              <AppStack style={{ gap: 16, padding: 16 }}>
                <AppStack style={{ gap: 0 }}>
                  <AppFlexbox
                    style={{
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                      Orders {ecomVendorPayout && `(${ecomOrders.length})`}
                    </AppText>

                    <AppMenu
                      control={
                        <Button color="dark" size="compact-sm" variant="subtle">
                          <Dots size={22} />
                        </Button>
                      }
                    >
                      <Menu.Item
                        onClick={() => onOpenModal(VIEW_ACTIONS_ENUM.EXPORT)}
                      >
                        Export details
                      </Menu.Item>
                    </AppMenu>
                  </AppFlexbox>
                </AppStack>

                {isCreate && (
                  <>
                    <AppFlexbox style={{ justifyContent: 'center' }}>
                      <AppText
                        style={{
                          fontSize: 14,
                          color: '#666',
                          textAlign: 'center',
                          fontStyle: 'italic'
                        }}
                      >
                        Please select the products you'd like to include in this
                        payout.
                      </AppText>
                    </AppFlexbox>
                    <AppFlexbox>
                      <AppCheckbox
                        checked={allOrdersSelected}
                        label={
                          allOrdersSelected ? 'Unselect all' : 'Select all'
                        }
                        onChange={() => {
                          setFormState({
                            ...formState,
                            pkEcomOrders: allOrdersSelected
                              ? ecomOrders
                                  .filter(
                                    (e) =>
                                      e.payoutEarned - e.payoutReceived === 0
                                  )
                                  .map((e) => e.pkEcomOrder)
                              : ecomOrders.map((e) => e.pkEcomOrder)
                          });
                        }}
                      />
                    </AppFlexbox>
                    <AppFlexbox
                      style={{
                        flexDirection: isLargeMobileOrSmaller ? 'column' : 'row'
                      }}
                    >
                      <DateInput
                        clearable
                        disabled={controlsDisabled || !isCreate}
                        label="Start date"
                        maxDate={filterState.endDate || new Date()}
                        onChange={(v) => {
                          if (formState.hasProductChanges) {
                            setFilterState({
                              ...filterState,
                              changeRequest: { startDate: v }
                            });
                          }
                          else {
                            setFilterState({
                              ...filterState,
                              startDate: v
                            });
                          }
                        }}
                        placeholder="Select a start date"
                        style={{ flex: 1 }}
                        value={filterState.startDate ?? null}
                      />

                      <DateInput
                        clearable
                        disabled={controlsDisabled || !isCreate}
                        label="End date"
                        maxDate={new Date()}
                        minDate={filterState.startDate}
                        onChange={(v) => {
                          if (formState.hasProductChanges) {
                            setFilterState({
                              ...filterState,
                              changeRequest: { endDate: v }
                            });
                          }
                          else {
                            setFilterState({
                              ...filterState,
                              endDate: v
                            });
                          }
                        }}
                        placeholder="Select an end date"
                        style={{ flex: 1 }}
                        value={filterState.endDate ?? null}
                      />
                    </AppFlexbox>
                  </>
                )}
              </AppStack>
              <Divider />
              <AppStack style={{ gap: 0, padding: 0 }}>
                {viewLoading ? (
                  <AppStack style={{ padding: 40, alignItems: 'center' }}>
                    <Loader color="dark" size={40} />
                  </AppStack>
                ) : ecomOrders.length === 0 ? (
                  <AppFlexbox
                    style={{
                      padding: 20,
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <AppText style={{ fontSize: 14, color: '#666' }}>
                      No orders found.
                    </AppText>
                  </AppFlexbox>
                ) : (
                  ecomOrders
                    .sort((a, b) => b.pkEcomOrder - a.pkEcomOrder)
                    .map((p, index) => {
                      const selected = formState.pkEcomOrders.includes(
                        p.pkEcomOrder
                      );

                      return (
                        <React.Fragment key={p.pkEcomOrder}>
                          {index > 0 && <Divider />}
                          <AdminVendorPayoutListItem
                            disabled={
                              controlsDisabled ||
                              p.payoutEarned - p.payoutReceived === 0
                            }
                            ecomOrderBalance={p}
                            isCreate={isCreate}
                            onSelect={
                              isCreate
                                ? () => {
                                    setFormState({
                                      ...formState,
                                      pkEcomOrders: selected
                                        ? formState.pkEcomOrders.filter(
                                            (f) => f !== p.pkEcomOrder
                                          )
                                        : [
                                            ...formState.pkEcomOrders,
                                            p.pkEcomOrder
                                          ],
                                      hasProductChanges: true
                                    });
                                  }
                                : null
                            }
                            payoutReceived={
                              payoutPayments
                                ?.filter((f) =>
                                  p.ecomOrderProducts.some(
                                    (s) =>
                                      s.pkEcomOrderProduct ===
                                      f.fkEcomOrderProduct
                                  )
                                )
                                .reduce((r, c) => r + c.payoutAmount, 0) ?? 0
                            }
                            selected={selected}
                          />
                        </React.Fragment>
                      );
                    })
                )}
              </AppStack>
            </AppStack>
          </AppCard>
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <AdminVendorInfoCard ecomVendor={ecomVendorDetails.ecomVendor} />
          {/* <AdminVendorPaymentProviderInfoCard
            ecomVendor={ecomVendorDetails.ecomVendor}
          /> */}

          <AppCard
            radius="md"
            shadow="xs"
            style={{ padding: 0 }}
            visibleFrom="lg"
            withBorder
          >
            <AppStack style={{ gap: 0 }}>
              <AppStack style={{ gap: 8, padding: 16 }}>
                <AppStack style={{ fontSize: 16, fontWeight: 500 }}>
                  Summary
                </AppStack>

                <AdminVendorPayoutSummary
                  chequeId={formState.chequeId}
                  fontSize={14}
                  loading={viewLoading}
                  payoutAmount={isCreate ? payoutAmount : payoutReceived}
                  reason={formState.reason}
                  totalOrderCount={selectedOrders.length}
                />
              </AppStack>
              {isCreate && (
                <>
                  <Divider />
                  <AppStack style={{ gap: 0, padding: 16 }}>
                    <Button
                      color="dark"
                      disabled={controlsDisabled || payoutAmount === 0}
                      type="submit"
                      variant="filled"
                    >
                      Submit Payout
                    </Button>
                  </AppStack>
                </>
              )}
            </AppStack>
          </AppCard>
        </AppStack>
      </AppFlexbox>

      <ConfirmModal
        confirmActionColor="blue"
        confirmActionText="Yes, change period"
        isOpen={!!filterState.changeRequest}
        message={
          <AppStack>
            <AppText style={{ fontSize: 16 }}>
              Are you sure you want to change the Payout period? This will reset
              the selected products.
            </AppText>
          </AppStack>
        }
        onCancel={() =>
          setFilterState({
            ...filterState,
            changeRequest: null
          })
        }
        onConfirm={() => {
          setFilterState({
            ...filterState,
            ...filterState.changeRequest,
            changeRequest: null
          });
        }}
        title="Change Payout period?"
      />

      <ConfirmModal
        confirmActionColor="dark"
        confirmActionText="Yes, submit Payout"
        isLoading={formState.loading}
        isOpen={formState.showConfirm}
        message={
          <AppStack style={{ gap: 16 }}>
            <AppText
              style={{ fontSize: 16, fontWeight: 500, textAlign: 'center' }}
            >
              Are you sure you want to submit this payout?
            </AppText>
            <Divider />
            <AdminVendorPayoutSummary
              chequeId={formState.chequeId}
              payoutAmount={payoutAmount}
              reason={formState.reason}
              totalOrderCount={selectedOrders.length}
            />
          </AppStack>
        }
        modalProps={{ size: 450 }}
        onCancel={() =>
          setFormState({
            ...formState,
            showConfirm: false
          })
        }
        onConfirm={onSubmitPayout}
        title="Submit Payout?"
      />

      <ConfirmModal
        confirmActionText="Export"
        isLoading={false}
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.EXPORT
        }
        message={
          <AppStack style={{ gap: 10 }}>
            <AppText>Export the vendor payout data to a CSV file.</AppText>

            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              Product details
            </AppText>
            <AppCheckbox
              checked={exportState.expandProductData}
              description="Show each product on a seperate line"
              label="Expand product details"
              onChange={() =>
                setExportState({
                  ...exportState,
                  expandProductData: !exportState.expandProductData
                })
              }
            />
          </AppStack>
        }
        onCancel={onCloseModal}
        onConfirm={() => {
          onExportPayoutDetails();
          onCloseModal();
        }}
        title="Export payout details?"
      />
    </AppStack>
  ) : (
    <AppStack
      p={{ base: 8, sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox
        style={{
          alignItems: 'start',
          justifyContent: 'space-between',
          gap: 8
        }}
      >
        <AppFlexbox style={{ gap: 8 }}>
          <ActionableIcon
            color="dark"
            component={Link}
            radius="md"
            style={{ marginTop: 5 }}
            to={`/admin/vendors/${pkEcomVendor}`}
            variant="subtle"
          >
            <ArrowLeft />
          </ActionableIcon>
          <AppStack style={{ gap: 5 }}>
            <Skeleton height={36} width={200} />
            <Skeleton height={14} width={150} />
          </AppStack>
        </AppFlexbox>
      </AppFlexbox>
      <AppFlexbox
        style={{
          flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row',
          width: '100%'
        }}
      >
        <AppStack style={{ flex: 1, width: '100%' }}>
          <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
            <AppStack style={{ gap: 0 }}>
              <AppStack style={{ gap: 8, padding: 16 }}>
                <Skeleton height={22} width={75} />

                <AppFlexbox
                  style={{
                    gap: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Skeleton height={18} width={100} />
                  <Skeleton height={18} width={50} />
                </AppFlexbox>

                <AppFlexbox
                  style={{
                    gap: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Skeleton height={18} width={100} />
                  <Skeleton height={18} width={50} />
                </AppFlexbox>
              </AppStack>
              <Divider />
              <AppStack style={{ gap: 8, padding: 16 }}>
                <AppFlexbox
                  style={{
                    gap: 8,
                    flexDirection: isLargeMobileOrSmaller ? 'column' : 'row'
                  }}
                >
                  <AppStack style={{ flex: 1, gap: 5 }}>
                    <Skeleton height={18} width="50%" />
                    <Skeleton height={35} width="100%" />
                  </AppStack>

                  <AppStack style={{ flex: 1, gap: 5 }}>
                    <Skeleton height={18} width="50%" />
                    <Skeleton height={35} width="100%" />
                  </AppStack>
                </AppFlexbox>
              </AppStack>
              <Divider />
              <AppStack style={{ padding: '8px 16px' }}>
                <Skeleton height={18} width="80%" />
              </AppStack>
            </AppStack>
          </AppCard>

          <AppCard
            hiddenFrom="lg"
            radius="md"
            shadow="xs"
            style={{ padding: 0 }}
            withBorder
          >
            <AppStack style={{ gap: 0 }}>
              <AppStack style={{ gap: 8, padding: 16 }}>
                <Skeleton height={16} width={100} />

                <AppFlexbox
                  style={{
                    gap: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Skeleton height={14} width={100} />
                  <Skeleton height={14} width={50} />
                </AppFlexbox>
                <AppFlexbox
                  style={{
                    gap: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Skeleton height={14} width={100} />
                  <Skeleton height={14} width={50} />
                </AppFlexbox>
                <AppFlexbox
                  style={{
                    gap: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Skeleton height={14} width={100} />
                  <Skeleton height={14} width={50} />
                </AppFlexbox>
                <AppFlexbox
                  style={{
                    gap: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Skeleton height={14} width={100} />
                  <Skeleton height={14} width={50} />
                </AppFlexbox>
              </AppStack>
              <Divider />
              <AppStack style={{ gap: 0, padding: 16 }}>
                <Skeleton height={35} width="100%" />
              </AppStack>
            </AppStack>
          </AppCard>
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <AdminVendorInfoCard />
          <AdminVendorPaymentProviderInfoCard />

          <AppCard
            radius="md"
            shadow="xs"
            style={{ padding: 0 }}
            visibleFrom="lg"
            withBorder
          >
            <AppStack style={{ gap: 0 }}>
              <AppStack style={{ gap: 8, padding: 16 }}>
                <Skeleton height={16} width={100} />

                <AppFlexbox
                  style={{
                    gap: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Skeleton height={14} width={100} />
                  <Skeleton height={14} width={50} />
                </AppFlexbox>
                <AppFlexbox
                  style={{
                    gap: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Skeleton height={14} width={100} />
                  <Skeleton height={14} width={50} />
                </AppFlexbox>
                <AppFlexbox
                  style={{
                    gap: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Skeleton height={14} width={100} />
                  <Skeleton height={14} width={50} />
                </AppFlexbox>
                <AppFlexbox
                  style={{
                    gap: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Skeleton height={14} width={100} />
                  <Skeleton height={14} width={50} />
                </AppFlexbox>
              </AppStack>
              <Divider />
              <AppStack style={{ gap: 0, padding: 16 }}>
                <Skeleton height={35} width="100%" />
              </AppStack>
            </AppStack>
          </AppCard>
        </AppStack>
      </AppFlexbox>
    </AppStack>
  );
};

AdminVendorPayoutView.propTypes = {
  isCreate: PropTypes.bool,
  loading: PropTypes.bool
};

export default AdminVendorPayoutView;
