/* eslint-disable import/no-cycle */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  NumberFormatter,
  Select,
  Skeleton,
  TextInput
} from '@mantine/core';
import { Lock } from 'tabler-icons-react';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppStack from '../../../../common/AppStack';
import AppText from '../../../../common/AppText';
import AppCard from '../../../../common/AppCard';
import { Context as AuthContext } from '../../../../../providers/AuthContextProvider';

const WsCheckoutCartSummary = ({
  checkoutCart,
  mediaQueries,
  loading,
  isGuest,
  guestEmail,
  onEmailChange,
  loadingCheckout,
  loadingMockCheckout,
  disabled,
  onSelectRosterPlayer,
  selectedPkEcomStoreRosterPlayer,
  ecomStoreRosterPlayers,
  ecomStoreTheme,
  onCheckout,
  onMockCheckout
}) => {
  const { isTabletOrSmaller } = mediaQueries;
  const currency = checkoutCart?.currency?.toUpperCase() ?? 'CAD';
  const { state: authState } = useContext(AuthContext);

  const adminEcomStore = authState.ecomStores.find(
    (s) => s.pkEcomStore === ecomStoreTheme.ecomStore.pkEcomStore
  );
  const mockCheckoutEnabled = !!adminEcomStore?.isDemoStore;

  return (
    <AppCard
      shadow="lg"
      style={{
        flex: 1,
        padding: 0,
        borderRadius: isTabletOrSmaller ? 0 : 8,
        width: '100%'
      }}
      withBorder={!isTabletOrSmaller}
    >
      <AppStack style={{ gap: 10, margin: isTabletOrSmaller ? 20 : 40 }}>
        <AppText size="lg" weight={700}>
          Order Summary
        </AppText>
        <AppFlexbox
          style={{
            justifyContent: 'space-between',
            flexWrap: 'nowrap',
            alignItems: 'center'
          }}
        >
          <AppText size="lg">Subtotal</AppText>
          {loading ? (
            <Skeleton height={15} width={65} />
          ) : (
            <AppFlexbox style={{ gap: 10 }}>
              <AppText size="lg">
                <NumberFormatter
                  decimalScale={2}
                  displayType="text"
                  fixedDecimalScale
                  prefix="$"
                  thousandSeparator
                  value={checkoutCart.subtotalInCents / 100}
                />
              </AppText>
              <AppText size="lg">{currency}</AppText>
            </AppFlexbox>
          )}
        </AppFlexbox>
        {!loading && checkoutCart.discountTotalInCents > 0 && (
          <AppFlexbox
            style={{
              justifyContent: 'space-between',
              flexWrap: 'nowrap',
              alignItems: 'center'
            }}
          >
            <AppText size="lg">Discount</AppText>
            <AppFlexbox style={{ gap: 10 }}>
              <AppText size="lg">
                <NumberFormatter
                  decimalScale={2}
                  displayType="text"
                  fixedDecimalScale
                  prefix="$"
                  thousandSeparator
                  value={(checkoutCart.discountTotalInCents / 100) * -1}
                />
              </AppText>
              <AppText size="lg">{currency}</AppText>
            </AppFlexbox>
          </AppFlexbox>
        )}
        {!loading && checkoutCart.taxTotalInCents > 0 && (
          <AppFlexbox
            style={{
              justifyContent: 'space-between',
              flexWrap: 'nowrap',
              alignItems: 'center'
            }}
          >
            <AppText size="lg">Taxes</AppText>
            <AppFlexbox style={{ gap: 10 }}>
              <AppText size="lg">
                <NumberFormatter
                  decimalScale={2}
                  displayType="text"
                  fixedDecimalScale
                  prefix="$"
                  thousandSeparator
                  value={checkoutCart.taxTotalInCents / 100}
                />
              </AppText>
              <AppText size="lg">{currency}</AppText>
            </AppFlexbox>
          </AppFlexbox>
        )}
        {!loading && checkoutCart.customerServiceFeeTotalInCents > 0 && (
          <AppFlexbox
            style={{
              justifyContent: 'space-between',
              flexWrap: 'nowrap',
              alignItems: 'center'
            }}
          >
            <AppText size="lg">Service Fee</AppText>
            <AppFlexbox style={{ gap: 10 }}>
              <AppText size="lg">
                <NumberFormatter
                  decimalScale={2}
                  displayType="text"
                  fixedDecimalScale
                  prefix="$"
                  thousandSeparator
                  value={checkoutCart.customerServiceFeeTotalInCents / 100}
                />
              </AppText>
              <AppText size="lg">{currency}</AppText>
            </AppFlexbox>
          </AppFlexbox>
        )}
        {checkoutCart && checkoutCart.tieredPricingFeeInCents > 0 && (
          <AppFlexbox
            style={{
              justifyContent: 'space-between',
              flexWrap: 'nowrap',
              alignItems: 'center'
            }}
          >
            <AppText size="lg">Service Fee</AppText>
            {loading ? (
              <Skeleton height={15} width={65} />
            ) : (
              <AppFlexbox style={{ gap: 10 }}>
                <AppText size="lg">
                  <NumberFormatter
                    decimalScale={2}
                    displayType="text"
                    fixedDecimalScale
                    prefix="$"
                    thousandSeparator
                    value={checkoutCart.tieredPricingFeeInCents / 100}
                  />
                </AppText>
                <AppText size="lg">{currency}</AppText>
              </AppFlexbox>
            )}
          </AppFlexbox>
        )}
        {checkoutCart && checkoutCart.totalInCents > 0 && (
          <AppFlexbox
            style={{
              justifyContent: 'space-between',
              flexWrap: 'nowrap',
              alignItems: 'center'
            }}
          >
            <AppText size="lg">Shipping</AppText>
            {loading ? (
              <Skeleton height={15} width={65} />
            ) : (
              <AppFlexbox style={{ gap: 10 }}>
                <AppText size="lg" style={{ color: 'green' }}>
                  Free
                </AppText>
              </AppFlexbox>
            )}
          </AppFlexbox>
        )}
      </AppStack>
      <Divider />
      <AppStack style={{ margin: isTabletOrSmaller ? 20 : 40, gap: 24 }}>
        <AppFlexbox
          style={{
            justifyContent: 'space-between',
            flexWrap: 'nowrap',
            alignItems: 'center'
          }}
        >
          <AppText size="lg" weight={700}>
            Total
          </AppText>
          {loading ? (
            <Skeleton height={15} width={65} />
          ) : (
            <AppFlexbox style={{ gap: 10 }}>
              <AppText size="lg" weight={700}>
                <NumberFormatter
                  decimalScale={2}
                  displayType="text"
                  fixedDecimalScale
                  prefix="$"
                  thousandSeparator
                  value={checkoutCart.totalInCents / 100}
                />
              </AppText>
              <AppText size="lg" weight={700}>
                {currency}
              </AppText>
            </AppFlexbox>
          )}
        </AppFlexbox>

        {ecomStoreRosterPlayers && ecomStoreRosterPlayers.length > 0 && (
          <Select
            clearable
            data={ecomStoreRosterPlayers.map((p) => ({
              label: p.name,
              value: p.pkEcomStoreRosterPlayer.toString()
            }))}
            description="Select the player that you are fundraising for"
            disabled={loadingCheckout || loading || disabled}
            label="Fundraiser individual"
            onChange={onSelectRosterPlayer}
            searchable
            value={selectedPkEcomStoreRosterPlayer}
          />
        )}
      </AppStack>

      <AppStack
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();

          onCheckout();
        }}
        style={{
          backgroundColor: '#000',
          color: '#FFF',
          opacity: 0.8,
          padding: isTabletOrSmaller ? 20 : 40,
          gap: 30
        }}
      >
        {isGuest && checkoutCart?.lineItems.length > 0 && (
          <TextInput
            autoComplete="on"
            disabled={loadingCheckout || loading || disabled}
            label="Email"
            onChange={(e) => onEmailChange(e.currentTarget.value)}
            placeholder="Email address"
            required
            type="email"
            value={guestEmail}
          />
        )}
        <Button
          id="checkout-order-summary-submit"
          style={{ display: 'none' }}
          type="submit"
        />
        <AppStack style={{ gap: 30 }}>
          <Button
            disabled={
              loadingMockCheckout ||
              disabled ||
              loading ||
              checkoutCart?.lineItems.length === 0
            }
            leftSection={<Lock />}
            loading={loadingCheckout}
            onClick={() => {
              document.getElementById('checkout-order-summary-submit').click();
            }}
            size="lg"
            type="button"
          >
            {isGuest ? 'Checkout as Guest' : 'Continue to Checkout'}
          </Button>

          {mockCheckoutEnabled && (
            <>
              <Divider style={{ color: '#666' }} />

              <Button
                color="dark"
                disabled={
                  disabled ||
                  loadingCheckout ||
                  loading ||
                  checkoutCart?.lineItems.length === 0
                }
                leftSection={<Lock />}
                loading={loadingMockCheckout}
                onClick={onMockCheckout}
                size="lg"
                type="button"
                variant="filled"
              >
                Checkout as mock order
              </Button>
            </>
          )}
        </AppStack>
      </AppStack>
    </AppCard>
  );
};

WsCheckoutCartSummary.propTypes = {
  checkoutCart: PropTypes.object,
  colorScheme: PropTypes.object,
  disabled: PropTypes.bool,
  ecomStoreRosterPlayers: PropTypes.array,
  ecomStoreTheme: PropTypes.object,
  guestEmail: PropTypes.string,
  isGuest: PropTypes.bool,
  loading: PropTypes.bool,
  loadingCheckout: PropTypes.bool,
  loadingMockCheckout: PropTypes.bool,
  mediaQueries: PropTypes.object,
  mockCheckoutEnabled: PropTypes.bool,
  onCheckout: PropTypes.func,
  onEmailChange: PropTypes.func,
  onMockCheckout: PropTypes.func,
  onSelectRosterPlayer: PropTypes.func,
  selectedPkEcomStoreRosterPlayer: PropTypes.string
};

export default WsCheckoutCartSummary;
