import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DEFAULT_COMPARE_DATE_OPTIONS,
  DEFAULT_DATE_OPTIONS
} from '../components/content/analytics/analyticsConfig';
import { getDateOnly } from './format';
import { getFromStorage, saveToStorage } from './storage';

const getDatePeriodNumber = (str) => {
  const match = str.match(/^-?\d+/);
  return match ? Number(match[0]) : 0;
};

const periodHandlers = {
  d: (date, num) => date.setDate(date.getDate() + num),
  w: (date, num) => date.setDate(date.getDate() + num * 7),
  m: (date, num) => date.setMonth(date.getMonth() + num),
  y: (date, num) => date.setFullYear(date.getFullYear() + num),
  q: (date, num) => date.setMonth(date.getMonth() + num * 3)
};

const getDateParamValue = (param) => {
  const lowerParam = param.toLowerCase();
  const period = lowerParam.slice(-1); // Get the last character
  const num = getDatePeriodNumber(param);

  const baseDate = new Date();
  if (periodHandlers[period]) {
    periodHandlers[period](baseDate, num);
    return baseDate;
  }
  return dayjs(param);
};

const getDefaultPeriodInterval = (startDate, endDate) => {
  const daysBetween = dayjs(endDate).diff(dayjs(startDate), 'day');

  if (daysBetween <= 2) {
    return 'hour';
  }
  if (daysBetween <= 30) {
    return 'day';
  }
  return 'month';
};

const areDatesEqual = (date1, date2) => {
  const normalizeDate = (date) =>
    new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();
  return normalizeDate(new Date(date1)) === normalizeDate(new Date(date2));
};

const processProductCounts = (
  analytics,
  dataLabel,
  compareDataLabel,
  dataKey,
  nameKey
) => {
  let chartData = analytics.data
    .map((d) => d[dataKey])
    .flat()
    .reduce((r, c) => {
      const existingProduct = r.find(
        (d) => d.vendorProductUuid === c.vendorProductUuid
      );
      if (existingProduct) {
        existingProduct[dataLabel] += c.count;
      }
      else {
        r.push({
          vendorProductUuid: c.vendorProductUuid,
          name: c[nameKey],
          [dataLabel]: c.count
        });
      }
      return r;
    }, []);

  if (analytics.compareData) {
    chartData = analytics.compareData
      .map((d) => d[dataKey])
      .flat()
      .reduce((r, c) => {
        const existingProduct = r.find(
          (d) => d.vendorProductUuid === c.vendorProductUuid
        );
        if (existingProduct) {
          existingProduct[compareDataLabel] =
            (existingProduct[compareDataLabel] ?? 0) + c.count;
        }
        else {
          r.push({
            vendorProductUuid: c.vendorProductUuid,
            name: c[nameKey],
            [compareDataLabel]: c.count
          });
        }
        return r;
      }, chartData);
  }

  return chartData
    .sort(
      (a, b) =>
        b[dataLabel] - a[dataLabel] ||
        b[compareDataLabel] - a[compareDataLabel] ||
        a.name.localeCompare(b.name)
    )
    .map((m) => ({
      ...m,
      [dataLabel]: m[dataLabel] || 0,
      [compareDataLabel]: m[compareDataLabel] || 0
    }));
};

const useAnalyticsFilter = ({
  defaultDateOption,
  includePeriod,
  includeCompareDateRange
} = {}) => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const searchParams = new URLSearchParams(search);
  const startParam = searchParams.get('start');
  const endParam = searchParams.get('end');
  const compareParam = searchParams.get('compare');
  const compareStartParam = searchParams.get('compareStart');
  const compareEndParam = searchParams.get('compareEnd');
  const periodParam = searchParams.get('period');
  const viewParam = searchParams.get('view');
  const viewTypeParam = searchParams.get('viewType');

  const datePeriod =
    startParam && endParam
      ? DEFAULT_DATE_OPTIONS.find(
          (f) => f.start === startParam && f.end === endParam
        )
      : DEFAULT_DATE_OPTIONS.find((f) => f.value === defaultDateOption) ||
        DEFAULT_DATE_OPTIONS.find((f) => f.isDefault);
  const { startDate, endDate } = datePeriod
    ? datePeriod.getDateRange()
    : {
        startDate: getDateParamValue(startParam),
        endDate: getDateParamValue(endParam)
      };

  const compareDatePeriod =
    DEFAULT_COMPARE_DATE_OPTIONS.find((f) => f.value === compareParam) ||
    DEFAULT_COMPARE_DATE_OPTIONS.find((f) => f.isDefault);
  const { startDate: compareStartDate, endDate: compareEndDate } =
    compareStartParam && compareEndParam
      ? {
          startDate: getDateParamValue(compareStartParam),
          endDate: getDateParamValue(compareEndParam)
        }
      : compareDatePeriod.getDateRange(datePeriod, startDate, endDate);

  const filter = {
    datePeriod,
    compareDatePeriod,
    startDate: startDate ? getDateOnly(startDate) : null,
    endDate: endDate ? getDateOnly(endDate) : null,
    compareStartDate: compareStartDate ? getDateOnly(compareStartDate) : null,
    compareEndDate: compareEndDate ? getDateOnly(compareEndDate) : null,
    periodInterval:
      periodParam?.toLowerCase() ??
      getDefaultPeriodInterval(startDate, endDate),
    view: viewParam,
    viewType: viewTypeParam
  };

  const getSearchParams = (newFilter) => {
    const newSearchParams = new URLSearchParams(search);
    newSearchParams.set(
      'start',
      newFilter.datePeriod
        ? newFilter.datePeriod.start
        : dayjs(newFilter.startDate).format('YYYY-MM-DD')
    );
    newSearchParams.set(
      'end',
      newFilter.datePeriod
        ? newFilter.datePeriod.end
        : dayjs(newFilter.endDate).format('YYYY-MM-DD')
    );
    if (includeCompareDateRange) {
      if (newFilter.compareDatePeriod) {
        newSearchParams.set('compare', newFilter.compareDatePeriod.value);
        newSearchParams.delete('compareStart');
        newSearchParams.delete('compareEnd');
      }
      else {
        newSearchParams.set(
          'compareStart',
          dayjs(newFilter.compareStartDate).format('YYYY-MM-DD')
        );
        newSearchParams.set(
          'compareEnd',
          dayjs(newFilter.compareEndDate).format('YYYY-MM-DD')
        );
        newSearchParams.delete('compare');
      }
    }
    newSearchParams.delete('view');
    newSearchParams.delete('viewType');
    if (newFilter.view) {
      newSearchParams.set('view', newFilter.view);
      if (newFilter.viewType) {
        newSearchParams.set('viewType', newFilter.viewType);
      }
    }

    if (includePeriod) {
      newSearchParams.set('period', newFilter.periodInterval);
    }

    return newSearchParams;
  };

  const onUpdateFilter = (newFilter) => {
    const newSearchParams = getSearchParams(newFilter);
    navigate(`${pathname}?${newSearchParams.toString()}`, { replace: true });
  };

  return {
    filter,
    onChange: onUpdateFilter,
    urlSearchParams: getSearchParams(filter)
  };
};

const useReportHistory = () => {
  const historyString = getFromStorage('reportHistory');
  const reportHistory = historyString
    ? JSON.parse(historyString)
    : {
        reports: [],
        params: {}
      };

  const onUpdateReportUuidHistory = (reportUuid) => {
    const newReportHistory = {
      ...reportHistory,
      reports: [
        {
          uuid: reportUuid,
          date: new Date()
        },
        ...reportHistory.reports.filter((f) => f.uuid !== reportUuid)
      ]
    };
    saveToStorage('reportHistory', JSON.stringify(newReportHistory));
  };

  return {
    reportHistory,
    onViewReport: onUpdateReportUuidHistory
  };
};

export {
  processProductCounts,
  useAnalyticsFilter,
  areDatesEqual,
  getDefaultPeriodInterval,
  useReportHistory
};
