/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import WsSectionContainer from '../WsSectionContainer';
import { Context as AuthContext } from '../../../../../providers/AuthContextProvider';
import { Context as StoreUserContext } from '../../../../../providers/StoreUserContextProvider';
import WsCheckoutCart from './WsCheckoutCart';
import { useCartController } from '../../../../../helpers/webstoreCheckoutHelper';
import { triggerNotification } from '../../../../../helpers/notification';
import WsCheckoutComplete from './WsCheckoutComplete';

const accountContent = [
  {
    path: '/checkout/cart',
    Component: WsCheckoutCart
  }
];

const WsCheckoutContent = ({
  baseStoreUrl,
  blocks,
  config,
  themeConfig,
  colorSchemes,
  mediaQueries,
  sort,
  pathname,
  editModeEnabled,
  builderHasAuthenticated,
  onAuthenticateEditMode,
  ecomStoreTheme,
  search,
  onNavigate,
  ...rest
}) => {
  const hasFetchedCart = useRef(false);
  const { fetchStoreCart } = useCartController();
  const { state: authState } = useContext(AuthContext);
  const { state, fetchEcomStoreRosterPlayers } = useContext(StoreUserContext);
  const colorScheme = colorSchemes[config.colorScheme - 1] ?? colorSchemes[0];
  const currentContent = accountContent.find((content) =>
    pathname.startsWith(content.path)
  );
  const paymentSecureId = new URLSearchParams(search).get('secureId');
  const paymentStatus = new URLSearchParams(search).get('status');
  const { isTabletOrSmaller } = mediaQueries;

  const showPaymentComplete = paymentStatus === 'success' && !!paymentSecureId;

  useEffect(() => {
    if (authState.tokenAttempted) {
      if (!showPaymentComplete && !hasFetchedCart.current) {
        fetchStoreCart(
          ecomStoreTheme.ecomStore.pkEcomStore,
          null,
          null,
          (error) => triggerNotification(error)
        );
        hasFetchedCart.current = true;
      }

      if (!state.ecomStoreRosterPlayers.hasFetched) {
        fetchEcomStoreRosterPlayers(ecomStoreTheme.ecomStore.pkEcomStore);
      }
    }
  }, [authState.tokenAttempted, showPaymentComplete]);

  useEffect(() => {
    if (!!paymentStatus && !showPaymentComplete) {
      onNavigate(`${baseStoreUrl}/checkout/cart`);
    }
  }, [paymentStatus]);

  return (
    <WsSectionContainer
      colorScheme={colorScheme}
      config={config}
      mediaQueries={mediaQueries}
      noPadding={isTabletOrSmaller}
      themeConfig={themeConfig}
      {...rest}
    >
      {showPaymentComplete ? (
        <WsCheckoutComplete
          baseStoreUrl={baseStoreUrl}
          blocks={blocks}
          colorSchemes={colorSchemes}
          config={config}
          ecomStoreTheme={ecomStoreTheme}
          editModeEnabled={editModeEnabled}
          loading={!authState.tokenAttempted}
          mediaQueries={mediaQueries}
          onAuthenticateEditMode={onAuthenticateEditMode}
          onNavigate={onNavigate}
          pathname={pathname}
          sort={sort}
          themeConfig={themeConfig}
          {...rest}
          secureId={paymentSecureId}
        />
      ) : (
        currentContent && (
          <currentContent.Component
            baseStoreUrl={baseStoreUrl}
            blocks={blocks}
            colorSchemes={colorSchemes}
            config={config}
            ecomStoreTheme={ecomStoreTheme}
            editModeEnabled={editModeEnabled}
            hasFetchedCart={hasFetchedCart.current}
            loading={!authState.tokenAttempted}
            mediaQueries={mediaQueries}
            onAuthenticateEditMode={onAuthenticateEditMode}
            onNavigate={onNavigate}
            pathname={pathname}
            sort={sort}
            themeConfig={themeConfig}
            {...rest}
          />
        )
      )}
    </WsSectionContainer>
  );
};

WsCheckoutContent.propTypes = {
  baseStoreUrl: PropTypes.string,
  blocks: PropTypes.array,
  builderHasAuthenticated: PropTypes.bool,
  colorSchemes: PropTypes.array,
  config: PropTypes.object,
  ecomStoreTheme: PropTypes.object,
  editModeEnabled: PropTypes.bool,
  mediaQueries: PropTypes.object,
  onAuthenticateEditMode: PropTypes.func,
  onNavigate: PropTypes.func,
  pathname: PropTypes.string,
  search: PropTypes.string,
  sort: PropTypes.number,
  themeConfig: PropTypes.object
};

export default WsCheckoutContent;
