import { useContext } from 'react';
import { Context as StoreContext } from '../providers/StoreContextProvider';
import { Context as StoreUserContext } from '../providers/StoreUserContextProvider';
import { Context as AuthContext } from '../providers/AuthContextProvider';
import { Context as HelperContext } from '../providers/HelperContextProvider';
import { getFromStorage, saveToStorage } from './storage';
import { INPUT_CONTROL_TYPE_ENUM } from '../config/constants';
import { uploadFileContentRecursively } from './awsHelper';

const cartStorageHelper = ({ isAdminCheckout = false, pkEcomOrder = null }) => {
  const getCartStorageId = (pkEcomStore) =>
    isAdminCheckout
      ? `store-cart-${pkEcomStore}-admin`
      : `store-cart-${pkEcomStore}`;

  const getGuestCartStorage = (pkEcomStore) => {
    const idString = getCartStorageId(pkEcomStore, isAdminCheckout);
    const cartString = getFromStorage(idString);
    let cart = {
      fkEcomStore: pkEcomStore,
      ecomStoreCartProducts: [],
      pkEcomOrder
    };
    if (!cartString) {
      saveToStorage(idString, JSON.stringify(cart));
    }
    else {
      cart = JSON.parse(cartString);
    }
    return cart;
  };

  const setGuestCartProducts = (pkEcomStore, ecomStoreCartProducts) => {
    const cart = getGuestCartStorage(pkEcomStore, isAdminCheckout);
    cart.ecomStoreCartProducts = ecomStoreCartProducts;
    saveToStorage(
      getCartStorageId(pkEcomStore, isAdminCheckout),
      JSON.stringify(cart)
    );

    return cart;
  };

  const setCartEmail = (pkEcomStore, email) => {
    const cart = getGuestCartStorage(pkEcomStore, isAdminCheckout);
    cart.email = email;
    saveToStorage(
      getCartStorageId(pkEcomStore, isAdminCheckout),
      JSON.stringify(cart)
    );
    return cart;
  };

  const setCartSecureId = (pkEcomStore, secureId) => {
    const cart = getGuestCartStorage(pkEcomStore, isAdminCheckout);
    cart.secureId = secureId;
    saveToStorage(
      getCartStorageId(pkEcomStore, isAdminCheckout),
      JSON.stringify(cart)
    );
    return cart;
  };

  const resetCart = (pkEcomStore) => {
    const cart = {
      secureId: null,
      email: null,
      fkEcomStore: pkEcomStore,
      ecomStoreCartProducts: [],
      pkEcomOrder
    };
    saveToStorage(
      getCartStorageId(pkEcomStore, isAdminCheckout),
      JSON.stringify(cart)
    );
    return cart;
  };

  const formatInputAnswers = (answers) =>
    answers
      ?.filter(
        (a) =>
          a.value &&
          (a.fkRegFormControlType !== INPUT_CONTROL_TYPE_ENUM.CHECKBOX ||
            a.value?.toLowerCase() === 'yes')
      )
      .map(({ fkEcomVendorProductInput, value, fkRegFormControlType }) => ({
        fkEcomVendorProductInput,
        value:
          fkRegFormControlType === INPUT_CONTROL_TYPE_ENUM.CHECKBOX
            ? 'yes'
            : value
      })) || [];

  const matchInputAnswers = (pAnswers, cAnswers) =>
    cAnswers?.length === pAnswers.length &&
    pAnswers.every((a) =>
      cAnswers.some(
        (e) =>
          e.fkEcomVendorProductInput === a.fkEcomVendorProductInput &&
          e.value?.trim() === a.value?.trim()
      )
    );

  const updateCartProduct = (cartProduct, productRequests) => {
    const productRequest = productRequests?.find(
      (c) =>
        c.uuid === cartProduct.uuid &&
        matchInputAnswers(
          formatInputAnswers(cartProduct.ecomStoreCartProductInputAnswers),
          formatInputAnswers(c.ecomStoreCartProductInputAnswers)
        )
    );

    return {
      ...cartProduct,
      count: cartProduct.count + (productRequest?.count || 0),
      ecomStoreCartProductInputAnswers: productRequest?.ecomStoreCartProductInputAnswers
        ? formatInputAnswers(productRequest.ecomStoreCartProductInputAnswers)
        : cartProduct.ecomStoreCartProductInputAnswers
    };
  };

  const addOrUpdateCartProduct = (guestCartProducts, productRequests) => {
    const updatedCartProducts = guestCartProducts.map((cartProduct) =>
      updateCartProduct(cartProduct, productRequests)
    );

    const newCartProducts =
      productRequests
        ?.filter(
          (c) =>
            !guestCartProducts.some(
              (p) =>
                c.uuid === p.uuid &&
                matchInputAnswers(
                  formatInputAnswers(p.ecomStoreCartProductInputAnswers),
                  formatInputAnswers(c.ecomStoreCartProductInputAnswers)
                )
            )
        )
        .map((c, index) => ({
          pkEcomStoreCartProduct: Date.now() + index,
          uuid: c.uuid,
          count: c.count,
          ecomStoreCartProductInputAnswers:
            formatInputAnswers(c.ecomStoreCartProductInputAnswers) || [],
          addToGuestCart: true
        })) ?? [];

    return [...updatedCartProducts, ...newCartProducts];
  };

  const onAddOrUpdateCartCallback = (
    pkEcomStore,
    data,
    updatedCartProducts
  ) => {
    const filteredProducts = updatedCartProducts
      .filter((requestProduct) =>
        data.ecomStoreCartProducts.some(
          (cartProduct) =>
            requestProduct.pkEcomStoreCartProduct ===
            cartProduct.pkEcomStoreCartProduct
        )
      )
      .map((p) => {
        const cartProduct = data.ecomStoreCartProducts.find(
          (c) => c.pkEcomStoreCartProduct === p.pkEcomStoreCartProduct
        );
        return {
          ...p,
          addToGuestCart: false,
          count: cartProduct.count
        };
      });

    setGuestCartProducts(pkEcomStore, filteredProducts);
  };

  return {
    getGuestCartStorage,
    setGuestCartProducts,
    setCartEmail,
    setCartSecureId,
    resetCart,
    formatInputAnswers,
    matchInputAnswers,
    updateCartProduct,
    addOrUpdateCartProduct,
    onAddOrUpdateCartCallback
  };
};

const useCartController = ({
  isAdminCheckout = false,
  pkEcomOrder = null
} = {}) => {
  const { state: authState } = useContext(AuthContext);
  const {
    state: helperState,
    toggleWebstoreCheckoutSummaryDrawer,
    toggleWebstoreContinueAsGuestModal
  } = useContext(HelperContext);
  const {
    state,
    fetchEcomStoreCart,
    fetchEcomStoreGuestCart,
    addProductsToCart,
    updateEcomStoreCartProducts,
    removeProductsFromCart,
    checkoutCart,
    checkoutGuestCart
  } = useContext(StoreUserContext);
  const {
    state: adminState,
    fetchEcomStoreOrderAdminCart,
    mockEcomStoreCartCheckout,
    checkoutAdminCart
  } = useContext(StoreContext);
  const {
    getGuestCartStorage,
    setGuestCartProducts,
    setCartEmail,
    setCartSecureId,
    resetCart,
    addOrUpdateCartProduct,
    onAddOrUpdateCartCallback
  } = cartStorageHelper({ isAdminCheckout, pkEcomOrder });

  const { loading, value: ecomStoreCart } = isAdminCheckout
    ? adminState.ecomOrderAdminCart
    : authState.isAuthenticated
    ? state.ecomStoreCart
    : state.ecomStoreGuestCart;
  const isCartFetching = loading || !authState.tokenAttempted;

  const fetchStoreCart = (
    pkEcomStore,
    data = null,
    onSuccessCallback = null,
    onErrorCallback = null
  ) => {
    if (isAdminCheckout) {
      const cart = getGuestCartStorage(pkEcomStore);
      fetchEcomStoreOrderAdminCart(
        pkEcomStore,
        data ?? { ecomStoreCartProducts: cart.ecomStoreCartProducts },
        onSuccessCallback,

        onErrorCallback
      );
    }
    else if (authState.isAuthenticated) {
      fetchEcomStoreCart(pkEcomStore);
    }
    else {
      const cart = getGuestCartStorage(pkEcomStore);
      fetchEcomStoreGuestCart(
        pkEcomStore,
        data ?? { ecomStoreCartProducts: cart.ecomStoreCartProducts },
        onSuccessCallback,
        onErrorCallback
      );
    }
  };

  const getCartProductError = ({
    ecomStoreProduct,
    ecomStoreCartProductInputAnswers
  }) => {
    if (ecomStoreProduct && ecomStoreCartProductInputAnswers) {
      const requiredProductInputs =
        ecomStoreProduct.ecomVendorProduct.ecomVendorProductInputs.filter(
          (i) => i.isRequired
        ) ?? [];

      const requiredInputMissingAnswer = requiredProductInputs.find(
        (i) =>
          !ecomStoreCartProductInputAnswers.find(
            (a) =>
              a.fkEcomVendorProductInput === i.pkEcomVendorProductInput &&
              !!(a.value?.trim() || a.file)
          )
      );
      if (requiredInputMissingAnswer) {
        return `'${requiredInputMissingAnswer.label}' is required`;
      }
    }

    return null;
  };

  const uploadProductAnswerMedia = (
    pkEcomStore,
    ecomStoreCartProducts,
    onSuccessCallback,
    onErrorCallback
  ) => {
    const productAnswerMedia = ecomStoreCartProducts.flatMap(
      (p) =>
        p.ecomStoreCartProductInputAnswers
          ?.filter((a) => !!a.file)
          .map((a) => ({ ...a, uuid: p.uuid })) ?? []
    );
    uploadFileContentRecursively(
      productAnswerMedia,
      `ecom-store-${pkEcomStore}-product-answer`,
      (mediaData) => {
        const requestedStoreProducts = ecomStoreCartProducts.map((p) => ({...p}));

        mediaData.forEach((a) => {
          const product = requestedStoreProducts.find((p) => p.uuid === a.uuid);
          const answer = product.ecomStoreCartProductInputAnswers.find(
            (pa) =>
              pa.fkRegFormControlType === a.fkRegFormControlType &&
              pa.fkEcomVendorProductInput === a.fkEcomVendorProductInput
          );
          answer.file = null;
          answer.value = a.src;
        });

        onSuccessCallback(requestedStoreProducts);
      },
      () => {
        onErrorCallback('Failed to upload product media');
      }
    );
  };

  const addStoreProducts = (
    pkEcomStore,
    { ecomStoreCartProducts = [], removeUnavailable = false },
    onSuccessCallback = null,
    onErrorCallback = null
  ) => {
    const cartProductErrors = ecomStoreCartProducts
      .map((c) => getCartProductError(c))
      .filter((e) => !!e);

    if (cartProductErrors.length > 0) {
      onErrorCallback(cartProductErrors[0], 'input');
    }
    else {
      uploadProductAnswerMedia(
        pkEcomStore,
        ecomStoreCartProducts,
        (requestedStoreProducts) => {
          if (!isAdminCheckout && authState.isAuthenticated) {
            addProductsToCart(
              pkEcomStore,
              {
                ecomStoreCartProducts: requestedStoreProducts,
                removeUnavailable
              },
              () => {
                toggleWebstoreCheckoutSummaryDrawer({ isOpen: true });
                if (onSuccessCallback) {
                  onSuccessCallback();
                }
              },
              (e) => onErrorCallback(e)
            );
          }
          else {
            const cart = getGuestCartStorage(pkEcomStore);
            if (
              !isAdminCheckout &&
              cart.ecomStoreCartProducts.length === 0 &&
              !helperState.webstore.continueAsGuestModal.hasBeenShown
            ) {
              toggleWebstoreContinueAsGuestModal({
                isOpen: true,
                ecomStoreCartProducts: requestedStoreProducts
              });
              onSuccessCallback();
            }
            else {
              const updatedCartProducts = addOrUpdateCartProduct(
                cart.ecomStoreCartProducts,
                requestedStoreProducts
              );

              fetchStoreCart(
                pkEcomStore,
                {
                  ecomStoreCartProducts: [...updatedCartProducts],
                  removeUnavailable
                },
                (data) => {
                  onAddOrUpdateCartCallback(
                    pkEcomStore,
                    data,
                    updatedCartProducts
                  );
                  toggleWebstoreCheckoutSummaryDrawer({ isOpen: true });
                  if (onSuccessCallback) {
                    onSuccessCallback();
                  }
                },
                (e) => onErrorCallback(e)
              );
            }
          }
        },
        onErrorCallback
      );
    }
  };

  const updateStoreCartProducts = (
    pkEcomStore,
    { ecomStoreCartProducts = [], removeUnavailable = false },
    onSuccessCallback = null,
    onErrorCallback = null
  ) => {
    const cartProductErrors = ecomStoreCartProducts
      .map((c) => getCartProductError(c))
      .filter((e) => !!e);

    if (cartProductErrors.length > 0) {
      onErrorCallback(cartProductErrors[0], 'input');
    }
    else {
      uploadProductAnswerMedia(
        pkEcomStore,
        ecomStoreCartProducts,
        (requestedStoreProducts) => {
          if (!isAdminCheckout && authState.isAuthenticated) {
            updateEcomStoreCartProducts(
              pkEcomStore,
              { ecomStoreCartProducts, removeUnavailable },
              onSuccessCallback,
              (e) => onErrorCallback(e)
            );
          }
          else {
            const cart = getGuestCartStorage(pkEcomStore);
            const updatedCartProducts = cart.ecomStoreCartProducts.map((p) => {
              const productRequest = requestedStoreProducts?.find(
                (c) => c.pkEcomStoreCartProduct === p.pkEcomStoreCartProduct
              );
              return {
                ...p,
                count: productRequest?.count || p.count,
                ecomStoreCartProductInputAnswers:
                  productRequest?.ecomStoreCartProductInputAnswers ||
                  p.ecomStoreCartProductInputAnswers
              };
            });

            fetchStoreCart(
              pkEcomStore,
              {
                ecomStoreCartProducts: [...updatedCartProducts],
                removeUnavailable
              },
              (data) => {
                onAddOrUpdateCartCallback(
                  pkEcomStore,
                  data,
                  updatedCartProducts
                );
                if (onSuccessCallback) {
                  onSuccessCallback();
                }
              },
              (e) => onErrorCallback(e)
            );
          }
        },
        onErrorCallback
      );
    }
  };

  const removeStoreProducts = (
    pkEcomStore,
    ecomStoreCartProducts,
    onSuccessCallback,
    onErrorCallback
  ) => {
    if (!isAdminCheckout && authState.isAuthenticated) {
      removeProductsFromCart(
        pkEcomStore,
        ecomStoreCartProducts.map((p) => p.pkEcomStoreCartProduct),
        onSuccessCallback,
        onErrorCallback
      );
    }
    else {
      const cart = getGuestCartStorage(pkEcomStore);
      const products = cart.ecomStoreCartProducts.filter(
        (f) =>
          !ecomStoreCartProducts.some(
            (p) => p.pkEcomStoreCartProduct === f.pkEcomStoreCartProduct
          )
      );
      fetchStoreCart(
        pkEcomStore,
        { ecomStoreCartProducts: [...products] },
        () => {
          setGuestCartProducts(pkEcomStore, products);

          if (onSuccessCallback) {
            onSuccessCallback();
          }
        },
        onErrorCallback
      );
    }
  };

  const removeUnavailableStoreCartProducts = (
    pkEcomStore,
    onSuccessCallback,
    onErrorCallback
  ) => {
    updateStoreCartProducts(
      pkEcomStore,
      { removeUnavailable: true },
      onSuccessCallback,
      onErrorCallback
    );
  };

  const onShowCartProductCustomization = (ecomStoreCartProduct) => {
    toggleWebstoreCheckoutSummaryDrawer({
      ...helperState.webstore.checkoutSummaryDrawer,
      showProductCustomization: true,
      ecomStoreCartProduct
    });
  };

  const checkoutStoreCart = (
    pkEcomStore,
    { email, installments, fkEcomStoreRosterPlayer, redirectPath },
    onSuccessCallback,
    onErrorCallback
  ) => {
    if (
      ecomStoreCart.checkoutCart.totalInCents !== 0 &&
      ecomStoreCart.checkoutCart.totalInCents / 100 < 5
    ) {
      onErrorCallback('Minimum order amount of $5 required');
    }
    else if (isAdminCheckout) {
      const cart = getGuestCartStorage(pkEcomStore);
      checkoutAdminCart(
        pkEcomStore,
        {
          email,
          installments,
          ecomStoreCartProducts: cart.ecomStoreCartProducts,
          fkEcomStoreRosterPlayer,
          redirectPath
        },
        (data) => {
          setCartSecureId(pkEcomStore, data.secureId);
          if (onSuccessCallback) {
            onSuccessCallback(data);
          }
        },
        onErrorCallback
      );
    }
    else if (authState.isAuthenticated) {
      checkoutCart(
        pkEcomStore,
        { installments, fkEcomStoreRosterPlayer },
        onSuccessCallback,
        onErrorCallback
      );
    }
    else {
      const cart = setCartEmail(pkEcomStore, email);
      checkoutGuestCart(
        pkEcomStore,
        {
          email,
          installments,
          ecomStoreCartProducts: cart.ecomStoreCartProducts,
          fkEcomStoreRosterPlayer
        },
        (data) => {
          setCartSecureId(pkEcomStore, data.secureId);
          if (onSuccessCallback) {
            onSuccessCallback(data);
          }
        },
        onErrorCallback
      );
    }
  };

  const mockStoreCartCheckout = (
    pkEcomStore,
    { fkEcomStoreRosterPlayer },
    onSuccessCallback,
    onErrorCallback
  ) => {
    if (
      ecomStoreCart.checkoutCart.totalInCents !== 0 &&
      ecomStoreCart.checkoutCart.totalInCents / 100 < 5
    ) {
      onErrorCallback('Minimum order amount of $5 required');
    }
    mockEcomStoreCartCheckout(
      pkEcomStore,
      { fkEcomStoreRosterPlayer },
      onSuccessCallback,
      onErrorCallback
    );
  };

  const clearCartBySecureId = (pkEcomStore, secureId) => {
    const cart = getGuestCartStorage(pkEcomStore);
    if (cart.secureId === secureId) {
      resetCart(pkEcomStore);
      fetchStoreCart(pkEcomStore);
    }
  };

  return {
    isGuest: authState.tokenAttempted && !authState.isAuthenticated,
    ecomStoreCart,
    isCartFetching,
    fetchStoreCart,
    addStoreProducts,
    updateStoreCartProducts,
    removeStoreProducts,
    removeUnavailableStoreCartProducts,
    checkoutStoreCart,
    mockStoreCartCheckout,
    onShowCartProductCustomization,
    getGuestCartStorage,
    clearCartBySecureId,
    resetCart
  };
};

export { useCartController };
