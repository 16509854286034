import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider } from '@mantine/core';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import {
  REPORT_FIELD_LOGIC_OPERATORS,
  REPORT_HEADER_TYPE_ENUM
} from './reportsConfig';
import ReportFilterInput from './ReportFilterInput';

const INITIAL_FILTER_STATE = {
  key: new Date().getTime(),
  field: null,
  compare: null,
  logicalOperator: REPORT_FIELD_LOGIC_OPERATORS[0].value,
  values: []
};

const ReportFilterModal = ({
  isOpen,
  onClose,
  onConfirm,
  reportHeaders,
  filters
}) => {
  const [formState, setFormState] = useState({
    filters: [INITIAL_FILTER_STATE]
  });

  const fieldsGroupedByCategory =
    reportHeaders
      .filter((f) => !f.type !== REPORT_HEADER_TYPE_ENUM.DATE)
      .reduce((r, c) => {
        const existing = r.find((f) => f.label === c.category);
        if (!existing) {
          r.push({
            label: c.category,
            fields: [c]
          });
        }
        else {
          existing.fields.push(c);
        }
        return r;
      }, []) ?? [];

  useEffect(() => {
    if (isOpen) {
      setFormState({
        filters:
          filters.length > 0
            ? filters.map((f, i) => ({
                key: f.key || new Date().getTime() + i,
                field: f.field,
                compare: f.compare,
                logicalOperator: f.logicalOperator,
                values: f.values
              }))
            : [INITIAL_FILTER_STATE]
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      formSectionProps={{
        onCancel: onClose,
        onSubmit: () => onConfirm(formState.filters),
        submitTitle: 'Apply',
        padding: 0
      }}
      isOpen={isOpen}
      onClose={onClose}
      title="Filter report"
    >
      <AppStack style={{ gap: 0 }}>
        <AppStack style={{ padding: 16, paddingBottom: 0 }}>
          <AppText
            style={{
              fontSize: 14,
              color: '#666',
              textAlign: 'center',
              fontStyle: 'italic'
            }}
          >
            Filters are applied directly to orders rather than to the report's
            grouping.
          </AppText>
        </AppStack>
        {formState.filters.length === 0 ? (
          <AppFlexbox style={{ padding: 16 }}>
            <AppText>No filters applied</AppText>
          </AppFlexbox>
        ) : (
          formState.filters.map((f, i) => (
            <React.Fragment key={f.key}>
              {i > 0 && <Divider />}
              <ReportFilterInput
                fieldOptionsGroupedByCategory={fieldsGroupedByCategory}
                filterState={f}
                onRemove={() => {
                  setFormState({
                    ...formState,
                    filters: formState.filters.filter(
                      (filter) => filter.key !== f.key
                    )
                  });
                }}
                onStateChange={(newFilterState) => {
                  setFormState({
                    ...formState,
                    filters: formState.filters.map((filter) =>
                      filter.key === f.key ? newFilterState : filter
                    )
                  });
                }}
                reportHeaders={reportHeaders}
              />
            </React.Fragment>
          ))
        )}
        <Divider />
        <AppStack style={{ padding: 16 }}>
          <AppFlexbox>
            <Button
              color="dark"
              onClick={() => {
                setFormState({
                  ...formState,
                  filters: [
                    ...formState.filters,
                    {
                      key: new Date().getTime(),
                      field: null,
                      compare: '',
                      logicalOperator: '',
                      values: []
                    }
                  ]
                });
              }}
              size="compact-sm"
              variant="outline"
            >
              Add filter
            </Button>
          </AppFlexbox>
        </AppStack>
      </AppStack>
    </ResponsiveModal>
  );
};

ReportFilterModal.propTypes = {
  filters: PropTypes.array,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  reportHeaders: PropTypes.array
};

export default ReportFilterModal;
