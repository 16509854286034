import { useLocation } from 'react-router-dom';
import {
  ECOM_ORDER_PRODUCT_STATUS_ENUM,
  PAYMENT_TYPE_ENUM
} from '../config/constants';

const groupStoreOrderProductData = (productData, groupByRefundValue = false) =>
  productData
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .reduce((r, c) => {
      const existing = r.find(
        (p) =>
          p.uuid === c.uuid &&
          p.addOns.length === c.addOns.length &&
          (!groupByRefundValue || p.refundValue === c.refundValue) &&
          p.addOns.every(
            (ao, i) =>
              ao.name === c.addOns[i].name && ao.value === c.addOns[i].value
          )
      );
      if (existing) {
        existing.quantity += c.quantity;
        existing.totalCost += c.totalCost;
        existing.vendorCost += c.vendorCost;
        existing.serviceFees += c.serviceFees;
        existing.basePrice += c.basePrice;
        existing.amountRemaining += c.amountRemaining;
        existing.ecomOrderProductBulkTransfers.push(
          ...(c.ecomOrderProductBulkTransfers || [])
        );
        existing.ecomOrderShippingProducts.push(...c.ecomOrderShippingProducts);
        existing.shippedCount += c.shippedCount;
        existing.ecomOrderProducts.push({ ...c });
      }
      else {
        r.push({
          ...c,
          ecomOrderProducts: [{ ...c }],
          ecomOrderShippingProducts: [...c.ecomOrderShippingProducts],
          ecomOrderProductBulkTransfers: c.ecomOrderProductBulkTransfers
            ? [...c.ecomOrderProductBulkTransfers]
            : []
        });
      }
      return r;
    }, []);

const translateStoreOrderProductData = (
  lineItem,
  product,
  ecomOrder,
  ecomOrderReturnProducts = []
) => {
  const {
    returnedProducts = [],
    cancelledProducts = []
  } = ecomOrderReturnProducts
    .filter(
      (f) =>
        f.fkEcomOrderProduct ===
        (product.pkEcomOrderProduct ||
          product.ecomOrderProduct?.pkEcomOrderProduct)
    )
    .reduce(
      (r, c) => {
        if (c.isCancelReturn) {
          r.cancelledProducts.push(c);
        }
        else {
          r.returnedProducts.push(c);
        }
        return r;
      },
      { returnedProducts: [], cancelledProducts: [] }
    );

  const ecomOrderShippingProducts = product.ecomOrderShippingProducts.filter(
    (p) => !ecomOrder.isBulkShipment || p.fkEcomOrder === ecomOrder.pkEcomOrder
  );
  const shippedCount = ecomOrderShippingProducts.reduce(
    (r2, c2) => r2 + c2.count,
    0
  );
  const returnedCount = returnedProducts.reduce((r2, c2) => r2 + c2.count, 0);
  const cancelledCount = cancelledProducts.reduce((r2, c2) => r2 + c2.count, 0);
  const amountRemaining =
    product.count - shippedCount - returnedCount - cancelledCount;

  const addOnCost = lineItem
    ? lineItem.addOns.reduce((r, c) => r + c.unitAmountInCents, 0)
    : product.ecomOrderProductInputAnswers.reduce((r, c) => {
        if (c.adjustedCost) {
          return r + c.adjustedCost;
        }
        return r;
      }, 0);

  const reimbursedAmount =
    product.ecomOrderProductPayments
      ?.filter(
        (f) =>
          f.fkRegFormSubmissionPaymentType.toString() ===
          PAYMENT_TYPE_ENUM.REIMBURSEMENT.toString()
      )
      .reduce((r, c) => r + c.amount, 0) ?? 0;

  const unitCost = lineItem
    ? lineItem.totalInCents
    : product.ecomStoreProductVariant.price + addOnCost;
  const totalQuantity = lineItem ? lineItem.quantity : product.count;

  return {
    key:
      lineItem?.entityId ||
      product.pkEcomOrderProductBulk ||
      product.pkEcomOrderProduct,
    purchaseDate: ecomOrder.purchaseDate,
    pkEcomOrderProduct: product.pkEcomOrderProduct,
    pkEcomOrderProductBulk: product.pkEcomOrderProductBulk,
    fkEcomOrder: product.fkEcomOrder,
    ecomOrder,
    fkEcomOrderProductStatus: product.fkEcomOrderProductStatus,
    to: `/merchant/products/${
      lineItem?.uuid || product.ecomStoreProductVariant.ecomStoreProduct.uuid
    }`,
    previewImage:
      lineItem?.previewImage ||
      product.ecomStoreProductVariant.ecomVendorProductVariant.previewImage ||
      product.ecomStoreProductVariant.ecomStoreProduct.ecomVendorProduct
        .previewImage,
    isBulkShipment: product.isBulkShipment,
    name:
      lineItem?.productName ||
      product.ecomStoreProductVariant.ecomStoreProduct.ecomVendorProduct.name,
    vendorName:
      product.ecomStoreProductVariant.ecomStoreProduct.ecomVendorProduct
        .vendorName,
    vendorCost:
      (product.ecomStoreProductVariant.ecomVendorProductVariant.price +
        product.vendorAddonPrice) *
      totalQuantity,
    sku: product.ecomStoreProductVariant.ecomVendorProductVariant.sku,
    barcode: product.ecomStoreProductVariant.ecomVendorProductVariant.barcode,
    uuid: product.ecomStoreProductVariant.ecomVendorProductVariant.uuid,
    productUuid: product.ecomStoreProductVariant.ecomStoreProduct.uuid,
    vendorProductUuid:
      product.ecomStoreProductVariant.ecomStoreProduct.ecomVendorProduct.uuid,
    productUrlHandle:
      product.ecomStoreProductVariant.ecomStoreProduct.urlHandle,
    weight: product.ecomStoreProductVariant.ecomVendorProductVariant.weight,
    weightUnit:
      product.ecomStoreProductVariant.ecomVendorProductVariant.weightUnit,
    quantity: totalQuantity,
    unitCost,
    totalCost: unitCost * totalQuantity,
    addonServiceFees: product.addonServiceFees * totalQuantity,
    serviceFees:
      (product.serviceFees + product.addonServiceFees) * totalQuantity,
    basePrice: (product.baseStorePrice + product.addonPrice) * totalQuantity,
    storeProfit: product.storeProfit,
    totalStoreProfit: product.storeProfit * totalQuantity,
    refundValue: product.refundValue - product.storeProfit,
    requireExactMinimum:
      product.ecomStoreProductVariant.ecomStoreProduct.ecomVendorProduct
        .requireExactMinimum,
    minimumQuantity:
      product.ecomStoreProductVariant.ecomStoreProduct.ecomVendorProduct
        .minimumQuantity,
    amountRemaining,
    ecomOrderShippingProducts,
    shippedCount,
    returnedProducts,
    cancelledProducts,
    returnedCount,
    cancelledCount,
    reimbursedAmount,
    ecomOrderProducts: [],
    ecomOrderProduct: product,
    ecomOrderProductBulkTransfers: product.ecomOrderProductBulkTransfers,
    ecomCustomerAddressShipping: product.ecomCustomerAddressShipping,
    addOns: lineItem?.addOns
      .map((a) => ({
        name: a.name,
        value: a.value,
        sort: a.sort
      }))
      .sort((a, b) => a.sort - b.sort) || [
      ...product.ecomStoreProductVariant.ecomVendorProductVariant.ecomVendorProductVariantOptions.map(
        (op) => ({
          name: op.name,
          value: op.value,
          sort: op.nameSort
        })
      ),
      ...product.ecomOrderProductInputAnswers
        .map((a) => ({
          name: a.label,
          value: a.value,
          sort: a.sort,
          isInputAnswer: true
        }))
        .sort(
          (a, b) =>
            a.sort -
            b.sort +
            product.ecomStoreProductVariant.ecomVendorProductVariant
              .ecomVendorProductVariantOptions.length
        )
    ],
    ecomOrderProductBulk:
      !ecomOrder.isBulkShipment && product.isBulkShipment
        ? product.ecomOrderProductBulk.map((b) => ({
            pkEcomOrderProductBulk: b.pkEcomOrderProductBulk,
            count: b.count,
            createdAt: b.createdAt,
            fkEcomOrderProductStatus: b.fkEcomOrderProductStatus,
            fkEcomOrderStatus: b.fkEcomOrderStatus,
            fkEcomOrder: b.fkEcomOrder,
            to: `/merchant/group-orders/${b.fkEcomOrder}`
          }))
        : null,
    ecomCustomer: product.ecomCustomer
  };
};

const translateStoreOrderData = (ecomOrder) => {
  const ecomOrderReturnProducts =
    ecomOrder?.ecomOrderReturns.reduce(
      (r, c) => [
        ...r,
        ...c.ecomOrderReturnProducts.map((p) => ({
          ...p,
          isCancelReturn: c.isCancelReturn
        }))
      ],
      []
    ) ?? [];

  const translatedProductData = ecomOrder
    ? [
        ...ecomOrder.checkoutBalance.lineItems.map((p) =>
          translateStoreOrderProductData(
            p,
            ecomOrder.ecomOrderProducts.find(
              (c) => c.pkEcomOrderProduct === p.entityId
            ),
            ecomOrder,
            ecomOrderReturnProducts
          )
        ),
        ...ecomOrder.ecomOrderProductBulk
          .filter(
            (b) =>
              ![
                ECOM_ORDER_PRODUCT_STATUS_ENUM.TRANSFERRED,
                ECOM_ORDER_PRODUCT_STATUS_ENUM.CANCELLED,
                ECOM_ORDER_PRODUCT_STATUS_ENUM.REFUNDED
              ].includes(b.fkEcomOrderProductStatus.toString())
          )
          .map((b) =>
            translateStoreOrderProductData(
              null,
              {
                ...b.ecomOrderProduct,
                ...b,
                fkEcomOrder: b.ecomOrderProduct.fkEcomOrder
              },
              ecomOrder,
              ecomOrderReturnProducts
            )
          )
      ]
    : [];

  const productData = groupStoreOrderProductData(translatedProductData);

  return {
    ecomOrderShippingProducts: productData
      .map((p) => p.ecomOrderShippingProducts)
      .flat(),
    ecomOrderProducts: productData.sort((a, b) => a.name.localeCompare(b.name)),
    ecomOrderProductsOriginal: translatedProductData,
    groupedEcomOrderProducts: [...productData]
      .reduce((r, c) => {
        const existing = r.find(
          (f) => f.vendorProductUuid === c.vendorProductUuid
        );
        if (existing) {
          existing.quantity += c.quantity;
          existing.totalCost += c.totalCost;
          existing.vendorCost += c.vendorCost;
          existing.serviceFees += c.serviceFees;
          existing.basePrice += c.basePrice;
          existing.amountRemaining += c.amountRemaining;
          existing.ecomOrderShippingProducts.push(
            ...c.ecomOrderShippingProducts
          );
          existing.shippedCount += c.shippedCount;
          existing.minimumAmountRequiredRemaining = Math.max(
            0,
            existing.minimumAmountRequiredRemaining - c.quantity
          );
          existing.ecomOrderProducts.push(c);

          if (c.ecomOrderProductBulkTransfer) {
            existing.ecomOrderProductBulkTransfers.push(
              ...c.ecomOrderProductBulkTransfers
            );
          }
        }
        else {
          r.push({
            ...c,
            addOns: [],
            ecomOrderProducts: [],
            ecomOrderProductBulkTransfers: c.ecomOrderProductBulkTransfer
              ? [...c.ecomOrderProductBulkTransfers]
              : [],
            ecomOrderShippingProducts: [...c.ecomOrderShippingProducts]
          });
        }
        return r;
      }, [])
      .map((c) => {
        const minimumAmountRequired = c.requireExactMinimum
          ? Math.ceil(c.quantity / c.minimumQuantity) * c.minimumQuantity
          : c.minimumQuantity;
        return {
          ...c,
          minimumAmountReachedCount: Math.floor(c.quantity / c.minimumQuantity),
          minimumAmountRequired,
          minimumAmountRequiredRemaining: Math.max(
            0,
            minimumAmountRequired - c.quantity
          )
        };
      }),
    ecomOrderProductBulkChanges: ecomOrder
      ? groupStoreOrderProductData([
          ...ecomOrder.ecomOrderProductBulk
            .filter((b) =>
              [
                ECOM_ORDER_PRODUCT_STATUS_ENUM.TRANSFERRED,
                ECOM_ORDER_PRODUCT_STATUS_ENUM.CANCELLED,
                ECOM_ORDER_PRODUCT_STATUS_ENUM.REFUNDED
              ].includes(b.fkEcomOrderProductStatus.toString())
            )
            .map((b) =>
              translateStoreOrderProductData(
                null,
                {
                  ...b.ecomOrderProduct,
                  ...b,
                  fkEcomOrder: b.ecomOrderProduct.fkEcomOrder
                },
                ecomOrder,
                ecomOrderReturnProducts
              )
            )
        ]).reduce(
          (r, c) => {
            switch (c.fkEcomOrderProductStatus.toString()) {
              case ECOM_ORDER_PRODUCT_STATUS_ENUM.CANCELLED:
              case ECOM_ORDER_PRODUCT_STATUS_ENUM.REFUNDED:
                r.cancelled.push(c);
                break;
              case ECOM_ORDER_PRODUCT_STATUS_ENUM.TRANSFERRED:
                r.transferred.push(c);
                break;
              default:
                break;
            }
            return r;
          },
          {
            cancelled: [],
            transferred: []
          }
        )
      : {
          cancelled: [],
          transferred: []
        },
    ecomCustomers: translatedProductData.reduce((r, c) => {
      const customer = c.ecomCustomer || ecomOrder.ecomCustomer;

      const existing = r.find(
        (f) => f.pkEcomCustomer === customer.pkEcomCustomer
      );
      if (!existing) {
        const products = groupStoreOrderProductData(
          translatedProductData.filter(
            (p) => p.ecomCustomer?.pkEcomCustomer === customer.pkEcomCustomer
          )
        );

        r.push({
          ...customer,
          ecomCustomerAddressShipping: products.filter(
            (f) => !!f.ecomCustomerAddressShipping
          )[0]?.ecomCustomerAddressShipping,
          products
        });
      }
      return r;
    }, [])
  };
};

const calculateStoreOrderRefundValues = (
  ecomOrder,
  productData,
  selectedEcomOrderProducts,
  unlockRefundAmount = false
) => {
  // Summarize selected products
  const selectedProductsSummary = ecomOrder
    ? selectedEcomOrderProducts.reduce(
        (summary, selectedProduct) => {
          const product = productData.find(
            (p) => p.pkEcomOrderProduct === selectedProduct.pkEcomOrderProduct
          );

          const count = Math.min(
            selectedProduct.count || 0,
            product.amountRemaining
          );

          return {
            unitCost: summary.unitCost + count * product.unitCost,
            vendorCost: summary.vendorCost + count * product.vendorCost,
            itemCount: summary.itemCount + count,
            refundValue: summary.refundValue + count * product.refundValue,
            storeProfit: summary.storeProfit + count * product.storeProfit
          };
        },
        {
          unitCost: 0,
          vendorCost: 0,
          itemCount: 0,
          refundValue: 0,
          storeProfit: 0
        }
      )
    : {};

  // Check if all items are refunded
  const totalItemCount = productData.reduce((acc, p) => acc + p.quantity, 0);
  const hasRefundedAllItems =
    selectedProductsSummary.itemCount >= totalItemCount;

  const { platformFeeAmount = 0, reimbursedAmount = 0 } =
    ecomOrder?.ecomOrderPayments.reduce(
      (r, c) => ({
        reimbursedAmount:
          c.fkRegFormSubmissionPaymentType.toString() ===
          PAYMENT_TYPE_ENUM.REIMBURSEMENT.toString()
            ? r.reimbursedAmount + c.amount
            : r.reimbursedAmount,
        platformFeeAmount:
          r.platformFeeAmount +
          (c.ecomStoreCartPaymentFee?.applicationFeeAmount || 0)
      }),
      { platformFeeAmount: 0, reimbursedAmount: 0 }
    ) || {};

  // Destructure fees from the checkout balance
  const {
    serviceFeeRecordedInCents: recordedServiceFee = 0,
    baseServiceFeeInCents: expectedServiceFee = 0,
    tieredPricingFeeInCents = 0,
    amountPaidInCents = 0,
    refundedAmountInCents = 0
  } = ecomOrder?.checkoutBalance || {};

  // Calculate fee adjustments
  const expectedVsRecordedServiceFeeDifference =
    recordedServiceFee - expectedServiceFee;

  // Calculate fulfillment costs
  const totalProductFulfillmentCost = productData.reduce(
    (total, product) => total + product.refundValue * product.quantity,
    0
  );
  const tiedUpProductCost =
    totalProductFulfillmentCost - selectedProductsSummary.refundValue;
  const remainingTieredPricing =
    platformFeeAmount - totalProductFulfillmentCost;

  // Adjust for Stripe fee rounding
  const stripeFeeRoundingAdjustment = ecomOrder?.isBulkShipment
    ? 0
    : totalProductFulfillmentCost -
      (platformFeeAmount - tieredPricingFeeInCents);
  const tieredPricing = ecomOrder?.isBulkShipment
    ? 0
    : tieredPricingFeeInCents - stripeFeeRoundingAdjustment;

  // Calculate total store profit
  const totalStoreProfit =
    amountPaidInCents -
    recordedServiceFee -
    totalProductFulfillmentCost -
    tieredPricing;

  // Calculate refund adjustments
  const tieredPricingReducer =
    selectedProductsSummary.itemCount > 0 && hasRefundedAllItems
      ? 0
      : Math.min(tieredPricing, remainingTieredPricing);
  const refundAmountReducer =
    recordedServiceFee + tiedUpProductCost + tieredPricingReducer;

  const maxRefundAmount =
    amountPaidInCents - refundAmountReducer + reimbursedAmount;
  const maxRefundAmountWithoutLosses = maxRefundAmount - refundedAmountInCents;

  // Calculate available refund amount
  const availableRefundAmount =
    (unlockRefundAmount ? amountPaidInCents : maxRefundAmount) -
    refundedAmountInCents;

  return {
    selectedItemCount: selectedProductsSummary.itemCount,
    selectedItemsTotalUnitCost: selectedProductsSummary.unitCost,
    selectedItemsStoreProfit: selectedProductsSummary.storeProfit,
    selectedItemsRefundValue: selectedProductsSummary.refundValue,
    amountPaid: amountPaidInCents,
    totalProductFulfillmentCost,
    hasRefundedAllItems,
    totalStoreProfit,
    serviceFeePaid: recordedServiceFee,
    tieredPricing,
    tiedUpProductCost,
    availableRefundAmount,
    refundedAmountInCents,
    reimbursedAmount,
    maxRefundAmountWithoutLosses,
    expectedVsRecordedServiceFeeDifference
  };
};

const getStoreBackPath = (defaultBack = '/merchant') => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const fromLocations = searchParams.get('from')?.split(',') || [];
  const fromId = searchParams.get('fromId');

  const firstFrom = fromLocations[0]?.toLocaleLowerCase();
  const params =
    fromLocations.length > 1 ? `?from=${fromLocations.slice(1).join(',')}` : [];

  switch (firstFrom) {
    case 'analytics':
      return `/merchant/analytics${params}`;
    case 'orders':
      return fromId
        ? `/merchant/orders/${fromId}${params}`
        : `/merchant/orders${params}`;
    case 'group-orders':
      return fromId
        ? `/merchant/group-orders/${fromId}${params}`
        : `/merchant/group-orders${params}`;
    default:
      return defaultBack;
  }
};

export {
  translateStoreOrderData,
  translateStoreOrderProductData,
  calculateStoreOrderRefundValues,
  getStoreBackPath
};
