import onApiRequest from './ApiRequestHandler';
import createDataProvider from './createDataProvider';
import { providerContextReducer } from './providerContextReducer';

const initialPaginatedState = {
  pageIndex: 1,
  pageSize: 50,
  totalCount: 0,
  totalPages: 0,
  totalNoFilterCount: 0,
  data: [],
  filter: null,
  loading: false,
  error: null
};

const initialState = {
  catalogCartProducts: { value: [], loading: false, error: null },
  catalogVendors: { value: [], loading: false, error: null },
  catalogVendorCollections: { value: [], loading: false, error: null },
  catalogProduct: { value: null, loading: false, error: null },
  catalogProducts: initialPaginatedState,
  catalogSummary: { value: null, loading: false, error: null },
  ecomNotifications: { value: null, loading: false, error: null },
  ecomOrder: { value: null, loading: false, error: null },
  ecomOrderAdminCart: { value: null, loading: false, error: null },
  ecomOrderPurchaseProducts: { value: [], loading: false, error: null },
  ecomStore: { value: null, loading: false, error: null },
  ecomStoreAddresses: { value: [], loading: false, error: null },
  ecomStoreAnalytics: { value: null, loading: false, error: null },
  ecomStoreEscrowSummary: { value: null, loading: false, error: null },
  ecomStores: { value: [], loading: false, error: null },
  ecomStoreSetupSummary: { value: null, loading: false, error: null },
  ecomStoreBlogPosts: { value: [], loading: false, error: null },
  ecomStoreCartTransactionDetails: { value: null, loading: false, error: null },
  ecomStoreCollections: { value: [], loading: false, error: null },
  ecomStoreCollection: { value: null, loading: false, error: null },
  ecomCustomer: { value: null, loading: false, error: null },
  ecomStoreResourceOptions: { value: null, loading: false, error: null },
  ecomStorePages: { value: [], loading: false, error: null },
  ecomStoreProduct: { value: null, loading: false, error: null },
  ecomStoreProductOverview: { value: null, loading: false, error: null },
  ecomStoreProducts: { value: [], loading: false, error: null },
  ecomStoreProductsExport: { value: [], loading: false, error: null },
  ecomStoreRosterPlayers: { value: [], loading: false, error: null },
  ecomStoreMedia: { value: [], loading: false, error: null },
  ecomStoreMenus: { value: [], loading: false, error: null },
  ecomStoreReport: { value: null, loading: false, error: null },
  ecomStoreTeamUsers: { value: [], loading: false, error: null },
  ecomStoreTheme: { value: null, loading: false, error: null },
  ecomStoreThemes: { value: [], loading: false, error: null },
  paginatedEcomOrders: initialPaginatedState,
  paginatedEcomOrdersBulk: initialPaginatedState,
  paginatedEcomOrdersAdmin: initialPaginatedState,
  paginatedEcomCustomers: initialPaginatedState,
  paginatedEcomStoreProducts: initialPaginatedState,
  paginatedEcomStoreRosterPlayers: initialPaginatedState
};

// #region Store
const createStore = (dispatch) => async (
  {
    name,
    domain,
    supportEmail,
    description,
    logoImageUrl,
    sandboxEnabled,
    primaryColor,
    secondaryColor,
    onlineStorePassword,
    onlineStorePasswordDescription,
    treasurerAlertEmail,
    fkCurrencyType,
    fkTeam,
    fkAssociation
  },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStores',
      url: '/api/ecommerce/admin/stores',
      type: 'post',
      data: {
        name,
        domain,
        supportEmail,
        description,
        logoImageUrl,
        sandboxEnabled,
        primaryColor,
        secondaryColor,
        onlineStorePassword,
        onlineStorePasswordDescription,
        treasurerAlertEmail,
        fkCurrencyType,
        fkTeam,
        fkAssociation
      }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchStoreSetupSummary = (dispatch) => async (
  pkEcomStore,
  { skipLoading } = {},
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreSetupSummary',
      url: `/api/ecommerce/admin/stores/${pkEcomStore}/setup-summary`,
      type: 'get',
      skipLoading
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchStores = (dispatch) => async (
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStores',
      url: '/api/ecommerce/stores',
      type: 'get'
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchStore = (dispatch) => async (
  pkEcomStore,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStore',
      url: `/api/ecommerce/admin/stores/${pkEcomStore}`,
      type: 'get',
      filter: { pkEcomStore },
      resetStateValue: null
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const updateStore = (dispatch) => async (
  pkEcomStore,
  {
    name,
    domain,
    supportEmail,
    description,
    logoImageUrl,
    sandboxEnabled,
    autoFulfillBulkOrders,
    primaryColor,
    primaryContrastColor,
    secondaryColor,
    secondaryAltColor,
    secondaryContrastColor,
    onlineStorePassword,
    onlineStorePasswordDescription,
    treasurerAlertEmail,
    urlHandle,
    fkCurrencyType,
    fkTeam,
    fkAssociation,
    ecomStoreWindow
  },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStore',
      successAction: 'success',
      url: `/api/ecommerce/admin/stores/${pkEcomStore}`,
      type: 'put',
      data: {
        name,
        domain,
        supportEmail,
        description,
        logoImageUrl,
        sandboxEnabled,
        autoFulfillBulkOrders,
        primaryColor,
        primaryContrastColor,
        secondaryColor,
        secondaryAltColor,
        secondaryContrastColor,
        onlineStorePassword,
        onlineStorePasswordDescription,
        treasurerAlertEmail,
        urlHandle,
        fkCurrencyType,
        fkTeam,
        fkAssociation,
        ecomStoreWindow
      }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const onboardStorePaymentProvider = (dispatch) => async (
  { pkEcomStore, accountId, updateAccount },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      url: `/api/payment/ecommerce/onboard`,
      type: 'post',
      data: {
        pkEcomStore,
        accountId,
        updateAccount
      }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const disconnectStorePaymentProvider = (dispatch) => async (
  { pkEcomStore },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      url: `/api/payment/ecommerce/onboard/disconnect`,
      type: 'post',
      data: { pkEcomStore }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchStoreCatalogVendors = (dispatch) => async (
  { pkEcomStore, pkEcomVendor },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'catalogVendors',
      url: `/api/ecommerce/admin/stores/catalog/vendors`,
      type: 'get',
      params: { pkEcomStore, pkEcomVendor }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchStoreCatalogVendorCollections = (dispatch) => async (
  { pkEcomStore, pkEcomVendor },
  { fkEcomVendor, isDemoMode },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'catalogVendorCollections',
      url: `/api/ecommerce/admin/stores/catalog/vendors/collections/${fkEcomVendor}`,
      type: 'get',
      params: { pkEcomStore, pkEcomVendor, isDemoMode }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchStoreCatalogProductsSummary = (dispatch) => async (
  { pkEcomStore, pkEcomVendor, isDemoMode },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'catalogSummary',
      url: `/api/ecommerce/admin/stores/catalog/summary`,
      type: 'get',
      params: { pkEcomStore, pkEcomVendor, isDemoMode }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchStoreCatalogProducts = (dispatch) => async (
  { pkEcomStore, pkEcomVendor },
  {
    fkEcomVendor,
    fkEcomCategory,
    fkProvince,
    fkEcomVendorCollection,
    fundraisingRecommended,
    search,
    sort,
    minPrice,
    maxPrice,
    page,
    pageSize,
    isDemoMode
  },
  appendResults = false,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'catalogProducts',
      url: `/api/ecommerce/admin/stores/catalog/products`,
      type: 'get',
      successAction: appendResults ? 'paginated-append' : 'success',
      transformPayload: (data) => ({ ...data }),
      params: {
        fkEcomVendor,
        fkEcomCategory,
        fkProvince,
        fkEcomVendorCollection,
        fundraisingRecommended,
        search,
        sort,
        minPrice,
        maxPrice,
        page,
        pageSize,
        pkEcomStore,
        pkEcomVendor,
        isDemoMode
      },
      resetStateValue: []
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchStoreCatalogProduct = (dispatch) => async (
  { pkEcomStore, pkEcomVendor },
  { fkEcomVendor, urlHandle },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'catalogProduct',
      url: `/api/ecommerce/admin/stores/catalog/products/${fkEcomVendor}`,
      type: 'get',
      params: { pkEcomStore, pkEcomVendor, urlHandle }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchStoreCatalogCheckoutSummary = (dispatch) => async (
  pkEcomStore,
  { uuids, skipLoading },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  const uuidsParam = uuids?.reduce(
    // eslint-disable-next-line no-return-assign
    (result, current, index) =>
      // eslint-disable-next-line no-param-reassign
      (result += `uuids[${index}]=${current}&`),
    ''
  );

  onApiRequest(
    {
      dispatch,
      stateName: 'catalogCartProducts',
      url: `/api/ecommerce/admin/stores/catalog/products/checkout-summary?pkEcomStore=${pkEcomStore}&${uuidsParam}`,
      type: 'get',
      skipLoading,
      params: { pkEcomStore }
    },
    onSuccessCallback,
    onErrorCallback
  );
};
// #endregion

// #region Products
const fetchPaginatedStoreProducts = (dispatch) => async (
  fkEcomStore,
  {
    search,
    fkEcomProductStatus,
    fkEcomPricingType,
    sort,
    page,
    pageSize,
    appendResults,
    skipLoading
  },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'paginatedEcomStoreProducts',
      url: `/api/ecommerce/admin/stores/products`,
      type: 'get',
      params: {
        fkEcomStore,
        search,
        fkEcomProductStatus,
        fkEcomPricingType,
        sort,
        page,
        pageSize,
        appendResults
      },
      successAction: appendResults ? 'paginated-append' : 'success',
      transformPayload: (data) => ({ ...data }),
      skipLoading
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchStoreProduct = (dispatch) => async (
  productUuid,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreProductOverview',
      url: `/api/ecommerce/admin/stores/products/${productUuid}`,
      type: 'get',
      filter: { productUuid },
      resetStateValue: null
    },
    (data) => {
      dispatch({
        type: 'success',
        stateName: 'ecomStoreProduct',
        payload: { value: data.ecomStoreProduct }
      });

      if (onSuccessCallback) {
        onSuccessCallback(data);
      }
    },
    onErrorCallback
  );
};

const fetchStoreProductOptions = (dispatch) => async (
  fkEcomStore,
  { skipLoading },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreProducts',
      url: `/api/ecommerce/admin/stores/products/options`,
      type: 'get',
      params: { fkEcomStore },
      successAction: 'success',
      skipLoading
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchStoreProductExportData = (dispatch) => async (
  { fkEcomStore, uuids, urlHandles, skipStateUpdate = false },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  const uuidsParam = uuids?.reduce(
    // eslint-disable-next-line no-return-assign
    (result, current, index) =>
      // eslint-disable-next-line no-param-reassign
      (result += `uuids[${index}]=${current}&`),
    ''
  );

  const urlHandlesParam = urlHandles?.reduce(
    // eslint-disable-next-line no-return-assign
    (result, current, index) =>
      // eslint-disable-next-line no-param-reassign
      (result += `urlHandles[${index}]=${current}&`),
    ''
  );

  onApiRequest(
    {
      url: `/api/ecommerce/admin/stores/products/export-data?fkEcomStore=${fkEcomStore}${
        uuidsParam ? `&${uuidsParam}` : ''
      }${urlHandlesParam ? `&${urlHandlesParam}` : ''}`,
      type: 'get',
      ...(skipStateUpdate
        ? {}
        : {
            dispatch,
            stateName: 'ecomStoreProductsExport',
            filter: { fkEcomStore, uuids },
            resetStateValue: []
          })
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const createStoreProducts = (dispatch) => async (
  pkEcomStore,
  { storeProducts, selectFirstItem },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: selectFirstItem
        ? 'ecomStoreProduct'
        : 'ecomStoreProductsExport',
      url: `/api/ecommerce/admin/stores/products/${pkEcomStore}`,
      type: 'post',
      data: storeProducts
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const createStoreProductsFromVendor = (dispatch) => async (
  pkEcomStore,
  {
    fkEcomVendor,
    fkEcomVendorCollection,
    pricingAmount,
    roundingAdjustment,
    fkEcomPricingType,
    fkEcomProductStatus
  },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreProductsExport',
      url: `/api/ecommerce/admin/stores/products/${pkEcomStore}/all`,
      type: 'post',
      data: {
        fkEcomVendor,
        fkEcomVendorCollection,
        pricingAmount,
        roundingAdjustment,
        fkEcomPricingType,
        fkEcomProductStatus
      }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const updateStoreProducts = (dispatch) => async (
  pkEcomStore,
  { ecomStoreProducts, selectFirstItem },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: selectFirstItem
        ? 'ecomStoreProduct'
        : 'ecomStoreProductsExport',
      url: `/api/ecommerce/admin/stores/products/${pkEcomStore}`,
      type: 'put',
      data: ecomStoreProducts,
      selectFirstItem,
      successAction: selectFirstItem ? 'success' : 'replace',
      removeFilter: (item) =>
        ecomStoreProducts.every((p) => p.uuid !== item.uuid)
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const deleteStoreProducts = (dispatch) => async (
  pkEcomStore,
  pkEcomStoreProducts,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  const pkEcomStoreProductsParam = pkEcomStoreProducts?.reduce(
    // eslint-disable-next-line no-return-assign
    (result, current, index) =>
      // eslint-disable-next-line no-param-reassign
      (result += `pkEcomStoreProducts[${index}]=${current}&`),
    ''
  );

  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreProducts',
      url: `/api/ecommerce/admin/stores/products/${pkEcomStore}?${pkEcomStoreProductsParam}`,
      type: 'delete',
      removeFilter: (item) =>
        !pkEcomStoreProducts.includes(item.pkEcomStoreProduct)
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchStoreCollections = (dispatch) => async (
  { fkEcomStore },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreCollections',
      url: '/api/ecommerce/admin/stores/collections',
      type: 'get',
      params: { fkEcomStore },
      resetStateValue: []
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchStoreCollection = (dispatch) => async (
  pkEcomStoreCollection,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreCollection',
      url: `/api/ecommerce/admin/stores/collections/${pkEcomStoreCollection}`,
      type: 'get',
      resetStateValue: []
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const createStoreCollections = (dispatch) => async (
  pkEcomStore,
  { ecomStoreCollections, selectFirstItem },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: selectFirstItem
        ? 'ecomStoreCollection'
        : 'ecomStoreCollection',
      url: `/api/ecommerce/admin/stores/collections/${pkEcomStore}`,
      type: 'post',
      selectFirstItem,
      data: ecomStoreCollections,
      successAction: selectFirstItem ? 'success' : 'append'
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const updateStoreCollection = (dispatch) => async (
  pkEcomStoreCollection,
  { name, description, ecomStoreCollectionProducts },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreCollection',
      url: `/api/ecommerce/admin/stores/collections/${pkEcomStoreCollection}`,
      type: 'put',
      data: {
        name,
        description,
        ecomStoreCollectionProducts
      },
      successAction: 'success'
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const deleteStoreCollections = (dispatch) => async (
  pkEcomStore,
  pkEcomStoreCollections,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  const pkEcomStoreCollectionsParam = pkEcomStoreCollections?.reduce(
    // eslint-disable-next-line no-return-assign
    (result, current, index) =>
      // eslint-disable-next-line no-param-reassign
      (result += `pkEcomStoreCollections[${index}]=${current}&`),
    ''
  );

  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreCollections',
      url: `/api/ecommerce/admin/stores/collections/${pkEcomStore}?${pkEcomStoreCollectionsParam}`,
      type: 'delete',
      removeFilter: (item) =>
        !pkEcomStoreCollections.includes(item.pkEcomStoreCollection)
    },
    onSuccessCallback,
    onErrorCallback
  );
};
// #endregion

// #region Webstore
const fetchStoreThemes = (dispatch) => async (
  pkEcomStore,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreThemes',
      url: `/api/ecommerce/admin/stores/themes`,
      type: 'get',
      params: { pkEcomStore },
      resetStateValue: []
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchStoreTheme = (dispatch) => async (
  uuid,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreTheme',
      url: `/api/ecommerce/admin/stores/themes/${uuid}`,
      type: 'get',
      resetStateValue: null
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const createStoreTheme = (dispatch) => async (
  pkEcomStore,
  { pkEcomStoreTheme, name },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreThemes',
      url: `/api/ecommerce/admin/stores/themes/${pkEcomStore}`,
      type: 'post',
      successAction: 'append',
      params: { pkEcomStoreTheme, name }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const updateStoreTheme = (dispatch) => async (
  pkEcomStoreTheme,
  {
    name,
    logoImageUrl,
    ecomStoreThemeTemplates,
    ecomStoreThemeColorSchemes,
    ecomStoreThemeProperties,
    ecomStoreThemeSections,
    replaceListState
  },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: replaceListState ? 'ecomStoreThemes' : 'ecomStoreTheme',
      url: `/api/ecommerce/admin/stores/themes/${pkEcomStoreTheme}`,
      type: 'put',
      data: {
        name,
        logoImageUrl,
        ecomStoreThemeTemplates,
        ecomStoreThemeColorSchemes,
        ecomStoreThemeProperties,
        ecomStoreThemeSections
      },
      successAction: replaceListState ? 'replace' : 'success',
      removeFilter: (item) => item.pkEcomStoreTheme !== pkEcomStoreTheme
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const deleteStoreTheme = (dispatch) => async (
  pkEcomStoreTheme,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreThemes',
      url: `/api/ecommerce/admin/stores/themes/${pkEcomStoreTheme}`,
      type: 'delete',
      removeFilter: (item) => item.pkEcomStoreTheme !== pkEcomStoreTheme
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const publishStoreTheme = (dispatch) => async (
  pkEcomStoreTheme,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      url: `/api/ecommerce/admin/stores/themes/${pkEcomStoreTheme}/publish`,
      type: 'post'
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchStoreMenus = (dispatch) => async (
  pkEcomStore,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreMenus',
      url: `/api/ecommerce/admin/stores/menus`,
      type: 'get',
      params: { pkEcomStore },
      resetStateValue: []
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const createStoreMenu = (dispatch) => async (
  pkEcomStore,
  { title, ecomStoreMenuItems },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreMenus',
      url: `/api/ecommerce/admin/stores/menus/${pkEcomStore}`,
      type: 'post',
      data: {
        title,
        ecomStoreMenuItems
      }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const updateStoreMenu = (dispatch) => async (
  pkEcomStoreMenu,
  { title, ecomStoreMenuItems },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreMenus',
      url: `/api/ecommerce/admin/stores/menus/${pkEcomStoreMenu}`,
      type: 'put',
      data: {
        title,
        ecomStoreMenuItems
      },
      successAction: 'replace',
      removeFilter: (item) => item.pkEcomStoreMenu !== pkEcomStoreMenu
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const deleteStoreMenu = (dispatch) => async (
  pkEcomStoreMenu,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreMenus',
      url: `/api/ecommerce/admin/stores/menus/${pkEcomStoreMenu}`,
      type: 'delete',
      removeFilter: (item) => item.pkEcomStoreMenu !== pkEcomStoreMenu
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchStorePages = (dispatch) => async (
  pkEcomStore,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStorePages',
      url: `/api/ecommerce/admin/stores/pages`,
      type: 'get',
      params: { pkEcomStore },
      resetStateValue: []
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const createStorePage = (dispatch) => async (
  pkEcomStorePage,
  { title, content },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStorePages',
      url: `/api/ecommerce/admin/stores/pages/${pkEcomStorePage}`,
      type: 'post',
      data: {
        title,
        content
      }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const updateStorePage = (dispatch) => async (
  pkEcomStorePage,
  { title, content },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStorePages',
      url: `/api/ecommerce/admin/stores/pages/${pkEcomStorePage}`,
      type: 'put',
      data: {
        title,
        content
      },
      successAction: 'replace',
      removeFilter: (item) => item.pkEcomStorePage !== pkEcomStorePage
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const deleteStorePage = (dispatch) => async (
  pkEcomStorePage,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStorePages',
      url: `/api/ecommerce/admin/stores/pages/${pkEcomStorePage}`,
      type: 'delete',
      removeFilter: (item) => item.pkEcomStorePage !== pkEcomStorePage
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchStoreBlogPosts = (dispatch) => async (
  pkEcomStore,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreBlogPosts',
      url: `/api/ecommerce/admin/stores/blogs`,
      type: 'get',
      params: { pkEcomStore },
      resetStateValue: []
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const createStoreBlogPost = (dispatch) => async (
  pkEcomStore,
  { title, description },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreBlogPosts',
      url: `/api/ecommerce/admin/stores/blogs/${pkEcomStore}`,
      type: 'post',
      data: {
        title,
        description
      }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const updateStoreBlogPost = (dispatch) => async (
  pkEcomStoreBlogPost,
  { title, description },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreBlogPosts',
      url: `/api/ecommerce/admin/stores/blogs/${pkEcomStoreBlogPost}`,
      type: 'put',
      data: {
        title,
        description
      },
      successAction: 'replace',
      removeFilter: (item) => item.pkEcomStoreBlogPost !== pkEcomStoreBlogPost
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const deleteStoreBlogPost = (dispatch) => async (
  pkEcomStoreBlogPost,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreBlogPosts',
      url: `/api/ecommerce/admin/stores/blogs/${pkEcomStoreBlogPost}`,
      type: 'delete',
      removeFilter: (item) => item.pkEcomStoreBlogPost !== pkEcomStoreBlogPost
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchStoreResourceOptions = (dispatch) => async (
  pkEcomStore,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreResourceOptions',
      url: `/api/ecommerce/admin/stores/resource-options`,
      type: 'get',
      params: { pkEcomStore }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchStoreMedia = (dispatch) => async (
  { fkEcomStore },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreMedia',
      url: `/api/ecommerce/admin/stores/media`,
      type: 'get',
      params: { fkEcomStore }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const createStoreMedia = (dispatch) => async (
  pkEcomStore,
  ecomStoreMedia,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreMedia',
      url: `/api/ecommerce/admin/stores/media/${pkEcomStore}`,
      type: 'post',
      data: ecomStoreMedia
    },
    onSuccessCallback,
    onErrorCallback
  );
};
// #endregion

// #region Fundraising
const fetchPaginatedStoreRosterPlayers = (dispatch) => async (
  pkEcomStore,
  {
    search,
    pkEcomStoreRosterPlayers,
    sort,
    page,
    pageSize,
    appendResults,
    skipLoading
  },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  const pkEcomStoreProductsParam = pkEcomStoreRosterPlayers?.reduce(
    // eslint-disable-next-line no-return-assign
    (result, current, index) =>
      // eslint-disable-next-line no-param-reassign
      (result += `playerIds[${index}]=${current}&`),
    ''
  );

  onApiRequest(
    {
      dispatch,
      stateName: 'paginatedEcomStoreRosterPlayers',
      url: `/api/ecommerce/admin/stores/fundraising/roster/players${
        pkEcomStoreProductsParam ? `?${pkEcomStoreProductsParam}` : ''
      }`,
      type: 'get',
      params: {
        pkEcomStore,
        search,
        sort,
        page,
        pageSize
      },
      successAction: appendResults ? 'paginated-append' : 'success',
      transformPayload: (data) => ({ ...data }),
      skipLoading
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchStoreRosterPlayers = (dispatch) => async (
  pkEcomStore,
  { pkEcomStoreRosterPlayers } = {},
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  const pkEcomStoreProductsParam = pkEcomStoreRosterPlayers?.reduce(
    // eslint-disable-next-line no-return-assign
    (result, current, index) =>
      // eslint-disable-next-line no-param-reassign
      (result += `playerIds[${index}]=${current}&`),
    ''
  );

  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreRosterPlayers',
      url: `/api/ecommerce/admin/stores/fundraising/roster/players/options${
        pkEcomStoreProductsParam ? `?${pkEcomStoreProductsParam}` : ''
      }`,
      type: 'get',
      params: { pkEcomStore },
      successAction: 'success'
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchStoreTeamUsers = (dispatch) => async (
  pkEcomStore,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreTeamUsers',
      url: `/api/ecommerce/admin/stores/fundraising/roster/team-users`,
      type: 'get',
      params: { pkEcomStore },
      successAction: 'success',
      resetStateValue: []
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const createStoreRosterPlayers = (dispatch) => async (
  pkEcomStore,
  { ecomStoreRosterPlayers },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreRosterPlayers',
      url: `/api/ecommerce/admin/stores/fundraising/roster/players/${pkEcomStore}`,
      type: 'post',
      successAction: 'success',
      data: ecomStoreRosterPlayers
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const updateStoreRosterPlayers = (dispatch) => async (
  pkEcomStore,
  { ecomStoreRosterPlayers },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreRosterPlayers',
      url: `/api/ecommerce/admin/stores/fundraising/roster/players/${pkEcomStore}`,
      type: 'put',
      successAction: 'success',
      data: ecomStoreRosterPlayers
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const deleteStoreRosterPlayers = (dispatch) => async (
  pkEcomStore,
  pkEcomStoreRosterPlayers,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  const pkEcomStoreProductsParam = pkEcomStoreRosterPlayers?.reduce(
    // eslint-disable-next-line no-return-assign
    (result, current, index) =>
      // eslint-disable-next-line no-param-reassign
      (result += `pkEcomStoreRosterPlayers[${index}]=${current}&`),
    ''
  );

  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreRosterPlayers',
      url: `/api/ecommerce/admin/stores/fundraising/roster/players/${pkEcomStore}?${pkEcomStoreProductsParam}`,
      type: 'delete',
      removeFilter: (item) =>
        !pkEcomStoreRosterPlayers.includes(item.pkEcomStoreRosterPlayer)
    },
    onSuccessCallback,
    onErrorCallback
  );
};
// #endregion

// #region Customer & Orders
const fetchPaginatedStoreOrders = (dispatch) => async (
  pkEcomStore,
  {
    search,
    status,
    isBulkShipment,
    escrowOrders,
    fkUserAdmin,
    vendorProductUuids,
    sort,
    page,
    pageSize,
    appendResults,
    pkEcomOrders,
    skipStateUpdate
  },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  const vendorProductUuidsParam = vendorProductUuids
    ? vendorProductUuids.reduce(
        // eslint-disable-next-line no-return-assign
        (result, current, index) =>
          // eslint-disable-next-line no-param-reassign
          (result += `vendorProductUuids[${index}]=${current}&`),
        ''
      )
    : '';

  const pkEcomOrdersParam = pkEcomOrders?.reduce(
    // eslint-disable-next-line no-return-assign
    (result, current, index) =>
      // eslint-disable-next-line no-param-reassign
      (result += `pkEcomOrders[${index}]=${current}&`),
    ''
  );

  onApiRequest(
    {
      url: `/api/ecommerce/admin/stores/${pkEcomStore}/orders?${vendorProductUuidsParam}${pkEcomOrdersParam}`,
      type: 'get',
      params: {
        pkEcomStore: fkUserAdmin ? null : pkEcomStore,
        search,
        status,
        isBulkShipment,
        escrowOrders,
        fkUserAdmin,
        sort,
        page,
        pageSize
      },
      transformPayload: (data) => ({ ...data }),
      ...(skipStateUpdate
        ? {}
        : {
            successAction: appendResults ? 'paginated-append' : 'success',
            dispatch,
            stateName: fkUserAdmin
              ? 'paginatedEcomOrdersAdmin'
              : isBulkShipment
              ? 'paginatedEcomOrdersBulk'
              : 'paginatedEcomOrders'
          })
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchStoreOrder = (dispatch) => async (
  pkEcomStore,
  { pkEcomOrder },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomOrder',
      url: `/api/ecommerce/admin/stores/${pkEcomStore}/orders/${pkEcomOrder}`,
      type: 'get',
      filter: { pkEcomStore, pkEcomOrder },
      successAction: 'success',
      resetStateValue: null
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const refundStoreOrder = (dispatch) => async (
  pkEcomStore,
  {
    pkEcomOrder,
    refundAmount,
    unlockRefundAmount,
    skipNotification,
    ecomOrderReturnProducts
  },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreCartTransactionDetails',
      url: `/api/payment/ecommerce/orders/${pkEcomOrder}/refund`,
      type: 'post',
      successAction: 'success',
      data: {
        refundAmount,
        skipNotification,
        unlockRefundAmount,
        pkEcomStore,
        ecomOrderReturnProducts
      },
      resetStateValue: null
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const cancelStoreOrder = (dispatch) => async (
  pkEcomStore,
  {
    pkEcomOrder,
    ecomOrderReturnProducts,
    cancelOrder,
    refundPayment,
    refundAmount,
    unlockRefundAmount,
    reason
  },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreCartTransactionDetails',
      url: `/api/payment/ecommerce/orders/${pkEcomOrder}/cancel`,
      type: 'post',
      successAction: 'success',
      data: {
        pkEcomStore,
        ecomOrderReturnProducts,
        cancelOrder,
        refundPayment,
        refundAmount,
        unlockRefundAmount,
        reason
      },
      resetStateValue: null
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const completeEcomOrderOffline = (dispatch) => async (
  pkEcomOrder,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomOrder',
      url: `/api/staff/ecommerce/stores/orders/${pkEcomOrder}/complete-offline`,
      type: 'post',
      data: {},
      successAction: 'success'
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const deleteDemoStoreOrder = (dispatch) => async (
  pkEcomStore,
  { pkEcomOrder },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      url: `/api/ecommerce/admin/stores/${pkEcomStore}/orders/${pkEcomOrder}/delete`,
      type: 'delete'
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const changeEcomStoreAddress = (dispatch) => async (
  pkEcomStore,
  { pkEcomOrder, pkEcomCustomerAddress },
  onSuccessCallback,
  onErrorCallback
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomOrder',
      url: `/api/ecommerce/admin/stores/${pkEcomStore}/orders/${pkEcomOrder}/address`,
      type: 'post',
      data: { pkEcomCustomerAddress },
      successAction: 'success'
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchEcomOrderPurchaseProducts = (dispatch) => async (
  pkEcomStore,
  { uuids = [] },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  const uuidsParam = uuids?.reduce(
    // eslint-disable-next-line no-return-assign
    (result, current, index) =>
      // eslint-disable-next-line no-param-reassign
      (result += `uuids[${index}]=${current}&`),
    ''
  );

  onApiRequest(
    {
      dispatch,
      stateName: 'ecomOrderPurchaseProducts',
      url: `/api/ecommerce/admin/stores/${pkEcomStore}/orders/purchase/products?${uuidsParam}`,
      type: 'get',
      successAction: 'success',
      data: {}
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchEcomStoreOrderAdminCart = (dispatch) => async (
  pkEcomStore,
  { ecomStoreCartProducts, removeUnavailable },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomOrderAdminCart',
      url: `/api/ecommerce/admin/stores/${pkEcomStore}/orders/purchase/cart`,
      type: 'post',
      data: {
        ecomStoreCartProducts,
        removeUnavailable
      },
      successAction: 'success'
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const checkoutAdminCart = (dispatch) => async (
  pkEcomStore,
  { email, installments, ecomStoreCartProducts, redirectPath },
  onSuccessCallback,
  onErrorCallback
) => {
  onApiRequest(
    {
      url: `/api/payment/ecommerce/cart/${pkEcomStore}/admin/checkout`,
      type: 'post',
      data: { email, installments, ecomStoreCartProducts, redirectPath }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const mockEcomStoreCartCheckout = (dispatch) => async (
  pkEcomStore,
  { fkEcomStoreRosterPlayer },
  onSuccessCallback,
  onErrorCallback
) => {
  onApiRequest(
    {
      url: `/api/ecommerce/stores/cart/${pkEcomStore}/mock-checkout`,
      type: 'post',
      data: { fkEcomStoreRosterPlayer }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const submitEcomStoreOrder = (dispatch) => async (
  pkEcomStore,
  {
    pkEcomOrder,
    skipNotification,
    orderSplitConfirmed,
    overrideSubmitRestrictions
  },
  onSuccessCallback,
  onErrorCallback
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomOrder',
      url: `/api/ecommerce/admin/stores/${pkEcomStore}/orders/${pkEcomOrder}/submit`,
      type: 'post',
      data: {
        skipNotification,
        orderSplitConfirmed,
        overrideSubmitRestrictions
      },
      successAction: 'success'
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const transferEcomStoreOrderProducts = (dispatch) => async (
  pkEcomStore,
  { pkEcomOrder, ecomOrderProductBulk },
  onSuccessCallback,
  onErrorCallback
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomOrder',
      url: `/api/ecommerce/admin/stores/${pkEcomStore}/orders/${pkEcomOrder}/transfer`,
      type: 'post',
      data: ecomOrderProductBulk,
      successAction: 'success'
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchPaginatedStoreCustomers = (dispatch) => async (
  pkEcomStore,
  { search, sort, page, pageSize, appendResults },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'paginatedEcomCustomers',
      url: `/api/ecommerce/admin/stores/${pkEcomStore}/customers`,
      type: 'get',
      params: {
        search,
        sort,
        page,
        pageSize
      },
      successAction: appendResults ? 'paginated-append' : 'success',
      transformPayload: (data) => ({ ...data })
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchStoreCustomer = (dispatch) => async (
  pkEcomStore,
  { pkEcomCustomer },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomCustomer',
      url: `/api/ecommerce/admin/stores/${pkEcomStore}/customers/${pkEcomCustomer}`,
      type: 'get',
      successAction: 'success',
      filter: { pkEcomStore, pkEcomCustomer },
      resetStateValue: null
    },
    onSuccessCallback,
    onErrorCallback
  );
};
// #endregion

// #region Reports & Analytics
const fetchStoreAnalytics = (dispatch) => async (
  pkEcomStore,
  { startDate, endDate, compareStartDate, compareEndDate },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreAnalytics',
      url: `/api/ecommerce/admin/stores/analytics`,
      type: 'get',
      successAction: 'success',
      params: {
        pkEcomStore,
        startDate,
        endDate,
        compareStartDate,
        compareEndDate
      }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const generateStoreReport = (dispatch) => async (
  pkEcomStore,
  {
    uuid,
    startDate,
    endDate,
    compareStartDate,
    compareEndDate,
    periodInterval,
    groupBy,
    headers,
    options,
    filters
  },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreReport',
      url: `/api/ecommerce/admin/stores/${pkEcomStore}/reports/generate/${uuid}`,
      type: 'post',
      successAction: 'success',
      data: {
        startDate,
        endDate,
        compareStartDate,
        compareEndDate,
        periodInterval,
        groupBy,
        headers,
        options,
        filters
      }
    },
    onSuccessCallback,
    onErrorCallback
  );
};
// #endregion

// #region Escrow Payments
const fetchStoreEscrowSummary = (dispatch) => async (
  pkEcomStore,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreEscrowSummary',
      url: `/api/ecommerce/admin/stores/${pkEcomStore}/escrow-summary`,
      type: 'get',
      successAction: 'success'
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const claimStoreEscrowPayments = (dispatch) => async (
  pkEcomStore,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      url: `/api/payment/ecommerce/store/${pkEcomStore}/claim-escrow`,
      type: 'post',
      successAction: 'success'
    },
    onSuccessCallback,
    onErrorCallback
  );
};
// #endregion

// #region Store Addresses
const fetchStoreAddresses = (dispatch) => async (
  pkEcomStore,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreAddresses',
      url: `/api/ecommerce/admin/stores/${pkEcomStore}/address/all`,
      type: 'get',
      successAction: 'success',
      filter: { pkEcomStore }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const createOrUpdateStoreAddresses = (dispatch) => async (
  pkEcomStore,
  ecomStoreAddresses,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreAddresses',
      url: `/api/ecommerce/admin/stores/${pkEcomStore}/address`,
      type: 'put',
      data: ecomStoreAddresses,
      successAction: 'success',
      removeFilter: (item) =>
        ecomStoreAddresses.every(
          (a) => a.pkEcomStoreAddress !== item.pkEcomStoreAddress
        )
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const deleteStoreAddresses = (dispatch) => async (
  pkEcomStore,
  pkEcomStoreAddresses,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  const pkEcomStoreAddressParam = pkEcomStoreAddresses?.reduce(
    // eslint-disable-next-line no-return-assign
    (result, current, index) =>
      // eslint-disable-next-line no-param-reassign
      (result += `pkEcomStoreAddress[${index}]=${current}&`),
    ''
  );

  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreAddresses',
      url: `/api/ecommerce/admin/stores/${pkEcomStore}/address?${pkEcomStoreAddressParam}`,
      type: 'delete',
      successAction: 'remove',
      removeFilter: (item) =>
        !pkEcomStoreAddresses.includes(item.pkEcomStoreAddress)
    },
    onSuccessCallback,
    onErrorCallback
  );
};
// #endregion

// #region Notifications
const fetchStoreNotifications = (dispatch) => async (
  pkEcomStore,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomNotifications',
      url: `/api/ecommerce/admin/stores/${pkEcomStore}/notifications`,
      type: 'get',
      successAction: 'success',
      filter: { pkEcomStore }
    },
    onSuccessCallback,
    onErrorCallback
  );
};
// #endregion

export const { Provider, Context } = createDataProvider(
  providerContextReducer,
  {
    cancelStoreOrder,
    changeEcomStoreAddress,
    checkoutAdminCart,
    claimStoreEscrowPayments,
    completeEcomOrderOffline,
    createOrUpdateStoreAddresses,
    createStore,
    createStoreBlogPost,
    createStoreCollections,
    createStoreMedia,
    createStoreMenu,
    createStorePage,
    createStoreProducts,
    createStoreRosterPlayers,
    createStoreProductsFromVendor,
    createStoreTheme,
    deleteDemoStoreOrder,
    deleteStoreAddresses,
    deleteStoreBlogPost,
    deleteStoreCollections,
    deleteStoreMenu,
    deleteStorePage,
    deleteStoreProducts,
    deleteStoreRosterPlayers,
    deleteStoreTheme,
    disconnectStorePaymentProvider,
    fetchEcomOrderPurchaseProducts,
    fetchEcomStoreOrderAdminCart,
    fetchPaginatedStoreOrders,
    fetchPaginatedStoreCustomers,
    fetchPaginatedStoreProducts,
    fetchPaginatedStoreRosterPlayers,
    fetchStore,
    fetchStoreAddresses,
    fetchStoreAnalytics,
    fetchStoreBlogPosts,
    fetchStoreCatalogCheckoutSummary,
    fetchStoreCatalogProduct,
    fetchStoreCatalogProducts,
    fetchStoreCatalogProductsSummary,
    fetchStoreCatalogVendorCollections,
    fetchStoreCatalogVendors,
    fetchStoreCollection,
    fetchStoreCollections,
    fetchStoreCustomer,
    fetchStoreEscrowSummary,
    fetchStoreMedia,
    fetchStoreMenus,
    fetchStoreNotifications,
    fetchStoreOrder,
    fetchStorePages,
    fetchStoreProduct,
    fetchStoreRosterPlayers,
    fetchStoreProductExportData,
    fetchStoreProductOptions,
    fetchStoreResourceOptions,
    fetchStores,
    fetchStoreSetupSummary,
    fetchStoreTeamUsers,
    fetchStoreTheme,
    fetchStoreThemes,
    generateStoreReport,
    mockEcomStoreCartCheckout,
    onboardStorePaymentProvider,
    publishStoreTheme,
    refundStoreOrder,
    submitEcomStoreOrder,
    transferEcomStoreOrderProducts,
    updateStoreMenu,
    updateStore,
    updateStoreBlogPost,
    updateStoreCollection,
    updateStorePage,
    updateStoreProducts,
    updateStoreRosterPlayers,
    updateStoreTheme
  },
  initialState
);
